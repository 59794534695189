function EventEmitter(object, logEvents) {
	var once_events = {};
	var events = {};

	logEvents = logEvents || false;
	if (!object) {
		object = {};
	}

	/**
	 * @param {string} evtName
	 * @param {function} callback
	 */
	object.on = function(evtName, callback) {
		if (events[evtName] === undefined) {
			events[evtName] = [callback];
		} else {
			events[evtName].push(callback);
		}
	};

	/**
	 * @param {string} evtName
	 * @param {function} callback
	 */
	object.once = function(evtName, callback) {
		if (once_events[evtName] === undefined) {
			once_events[evtName] = [callback];
		} else {
			once_events[evtName].push(callback);
		}
	};

	/**
	 * @param {string} evtName
	 * @param {object} data
	 */
	object.emit = function(evtName, data) {
		if (logEvents) {
			Logger.debug('EVENT[' + evtName + '] -> ', data);
		}
		var i;
		if (events[evtName] !== undefined) {
			for (i = 0 ; i < events[evtName].length ; i++) {
				events[evtName][i].call(object, data);
			}
		}
		if (once_events[evtName] !== undefined) {
			for (i = 0 ; i < once_events[evtName].length ; i++) {
				once_events[evtName][i].call(object, data);
			}
			once_events[evtName] = undefined;
		}
	};

	/**
	 * @param {string} evtName
	 * @param {function} callback
	 */
	object.removeListener = function(evtName, callback) {
		if (events[evtName] === undefined) { return; }
		var i = events[evtName].indexOf(callback);
		if (i >= 0) {
			events[evtName].splice(i, 1);
		}
	};

	/**
	 * @param {string} evtName
	 */
	object.removeAllListener = function(evtName) {
		events[evtName] = undefined;
		once_events[evtName] = undefined;
	};
}


var Logger = {
	ERROR: 3,
	WARN: 3,
	INFO: 6,
	DEBUG: 7
};

(function(Logger) {
	Logger.level = Logger.INFO;

	Logger.log = function log(level, args) {
		if (level > this.level) { return; }
		switch (level) {
		case Logger.ERROR:
			console.error.apply(console, args);
			break;
		case Logger.WARN:
			console.warn.apply(console, args);
			break;
		case Logger.INFO:
			console.log.apply(console, args);
			break;
		case Logger.DEBUG:
			console.debug.apply(console, args);
			break;
		default:
			break;
		}
	};

	Logger.error = function error() {
		var args = new Array(arguments.length);
		for (var i = 0; i < args.length; ++i) {
			args[i] = arguments[i];
		}
		this.log(Logger.ERROR, args);
	};

	Logger.warn = function warn() {
		var args = new Array(arguments.length);
		for (var i = 0; i < args.length; ++i) {
			args[i] = arguments[i];
		}
		this.log(Logger.WARN, args);
	};

	Logger.info = function info() {
		var args = new Array(arguments.length);
		for (var i = 0; i < args.length; ++i) {
			args[i] = arguments[i];
		}
		this.log(Logger.INFO, args);
	};

	Logger.debug = function debug() {
		var args = new Array(arguments.length);
		for (var i = 0; i < args.length; ++i) {
			args[i] = arguments[i];
		}
		this.log(Logger.DEBUG, args);
	};
})(Logger);

var Utils = {};

Utils.stringifyTime = function(seconds){
	if (isNaN(seconds)) { return '--:--'; }
	var h = Math.floor(seconds / 3600);
	var m = Math.floor(seconds / 60) - h * 60;
	var s = Math.floor(seconds % 60);
	var time = '';
	if (h !== 0){
		h = h.toString();
		time += h + ':';
	}
	m = m.toString();
	while (m.length < 2){m = '0' + m;}
	time += m + ':';
	s = s.toString();
	while (s.length < 2){s = '0' + s;}
	time += s;
	if (time[0] == '0') {
		return time.slice(1);
	}
	return time;
};

Utils.convertYTtime = function(duration) {
	var total = 0;
	var hours = duration.match(/(\d+)H/);
	var minutes = duration.match(/(\d+)M/);
	var seconds = duration.match(/(\d+)S/);
	if (hours) total += parseInt(hours[1]) * 3600;
	if (minutes) total += parseInt(minutes[1]) * 60;
	if (seconds) total += parseInt(seconds[1]);
	return total;
};

Utils.stringifycountView = function(count){
	var units = ['k', 'M', 'kM', 'mM'];
	count = count.toString();
	var l = count.length;
	var powerUnit = Math.floor((l - 1) / 3) - 1;
	if (l <= 3){
		return count;
	}
	switch (l % 3){
	case 0:
		return count.slice(0,3) + units[powerUnit];
	case 1:
		return count.slice(0, 1) + ',' + count.slice(1, 2) + units[powerUnit];
	case 2:
		return count.slice(0, 2) + units[powerUnit];
	}
	return count;
};

// Full screen utils
Utils.launchIntoFullscreen = function(element) {
	if (element.requestFullscreen) {
		element.requestFullscreen();
	} else if (element.mozRequestFullScreen) {
		element.mozRequestFullScreen();
	} else if (element.webkitRequestFullScreen) {
		element.webkitRequestFullScreen();
	} else if (element.msRequestFullscreen) {
		element.msRequestFullscreen();
	}
};
Utils.exitFullscreen = function() {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	}
};
Utils.isFullscreen = function() {
	if (document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement){
		return true;
	} else {
		return false;
	}
};

Utils.isHover = function(e) {
	return (e.parentElement.querySelector(':hover') === e);
};

Utils.isMobileUser = function() { 
	if ( navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
	){
		return true;
	}
	else {
		return false;
	}
};


Utils.popupCenter = function(url, title, w, h) {
	// Fixes dual-screen position                           Most browsers      Firefox
	var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
	var dualScreenTop = window.screenTop !== undefined   ? window.screenTop  : window.screenY;

	var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

	var systemZoom = Math.max(1, width / window.screen.availWidth);
	var left = (width - w) / 2 / systemZoom + dualScreenLeft;
	var top = (height - h) / 2 / systemZoom + dualScreenTop;
	var newWindow = window.open(url, title,
		'scrollbars=yes,'
		+ 'width=' + (w / systemZoom) + ','
		+ 'height=' + (h / systemZoom) + ','
		+ 'top=' + (top) + ','
		+ 'left=' + (left)
	);

	if (window.focus) newWindow.focus();
};

var View = function View() {
	this.element = null;
};

View.prototype.hide = function() {
	if (this.element) {
		this.element.hidden = true;
		this.element.classList.add('hidden');
	}
};

View.prototype.show = function() {
	if (this.element) {
		this.element.hidden = false;
		this.element.classList.remove('hidden');
	}
};

View.prototype.isVisible = function() {
	return this.element && (this.element.hidden === false);
};

var TiPlayerApp = {
	modules: []
};

var DarkMode = function DarkMode(app) {
	this.app = app;

	app.on('toogleDarkMode', function() {
		document.body.classList.toggle('dark');

		var theme = document.body.classList.contains('dark') ? 'dark' : '';
		localStorage.setItem('main-theme', theme);
	}.bind(this));
};

DarkMode.moduleName = DarkMode.prototype.moduleName = 'darkMode';

TiPlayerApp.modules.push(DarkMode);

var Loader = function Loader(app) {
	this.app = app;

	var loadingDiv = document.querySelector('#loadingDiv');
	var loadingElm = loadingDiv.querySelector('.loading_status_text');

	app.on('initialized', function() {
		loadingElm.innerHTML = 'Connection au serveur';
	});

	app.on('accountReady', function() {
		loadingElm.innerHTML = 'Connecté';
		loadingDiv.addEventListener('animationend', removeLoginDiv);
		loadingDiv.addEventListener('transitionend', removeLoginDiv);
		setTimeout(removeLoginDiv, 500);
		loadingDiv.classList.add('transparent');
	});

	function removeLoginDiv() {
		if (loadingDiv) loadingDiv.remove();
	}
};

Loader.moduleName = Loader.prototype.moduleName = 'loader';

TiPlayerApp.modules.push(Loader);


window.addEventListener('load', function() {

	// Configure
	Logger.level = getNumberSetting('logLevel', Logger.INFO);
	var logAllEvents = getBoolSetting('logAllAppEvents', false);

	// Init reserved keyword of App
	// Use only websocket
	TiPlayerApp.socket = io({transports: ['websocket'], autoConnect: false});
	EventEmitter(TiPlayerApp, logAllEvents);

	TiPlayerApp.socket._requestId = 0;
	TiPlayerApp.socket.emitWithResponse = function(eventName, data, callback) {
		var socket = TiPlayerApp.socket;
		var requestId = this._requestId++;

		socket.emit(eventName, data, requestId);
		socket.on(eventName + '/success', ifIsConcerned(onSuccess));
		socket.on(eventName + '/error', ifIsConcerned(onError));


		function ifIsConcerned(callback) {
			return function(data, id) {
				if (id === requestId) {
					callback(data);
				}
			};
		}

		function onSuccess(data) {
			removeListeners();
			callback(null, data);
		}
		function onError(err) {
			removeListeners();
			callback(err || 'Unknown error');
		}

		function removeListeners() {
			socket.removeListener(eventName + '/success', onSuccess);
			socket.removeListener(eventName + '/error', onError);
		}
	};

	// Create modules
	for (var i = 0 ; i < TiPlayerApp.modules.length ; i++) {
		var module = TiPlayerApp.modules[i];

		if (TiPlayerApp[module.moduleName] !== undefined) {
			Logger.error('Module name ', module.moduleName, ' is not accepted (reserved or already used)');
			continue;
		}

		TiPlayerApp[module.moduleName] = new module(TiPlayerApp);
	}
	TiPlayerApp.emit('initialized');


	// Start app
	TiPlayerApp.socket.connect();
	TiPlayerApp.emit('startConnection');

	TiPlayerApp.socket.on('serverTime', function(date) {
		TiPlayerApp.serverTimeOffset = date - new Date().getTime();
	});

	function getNumberSetting(key, defaultValue) {
		var value = parseInt(localStorage.getItem(key), 10);
		if (isNaN(value)) {
			value = defaultValue;
			localStorage.setItem(key, value);
		}
		return value;
	}

	function getBoolSetting(key, defaultValue) {
		var rawValue = localStorage.getItem(key);
		var value;
		if (rawValue == 'true' || rawValue == 'false') {
			value = Boolean(rawValue);
		} else {
			value = defaultValue;
			localStorage.setItem(key, value);
		}
		return value;
	}
});

if ('serviceWorker' in navigator) {
	window.addEventListener('load', function() {
		navigator.serviceWorker.register('sw.js').then(function(registration) {
			// Registration was successful
			console.log('ServiceWorker registration successful with scope: ', registration.scope);
		}, function(err) {
			// registration failed :(
			console.log('ServiceWorker registration failed: ', err);
		});
	});
}
var ShortcutManager = function(app) {
	this.app = app;
	this.bindEvents();
};

ShortcutManager.moduleName = ShortcutManager.prototype.moduleName = 'shortcutManager';
TiPlayerApp.modules.push(ShortcutManager);

ShortcutManager.prototype.bindEvents = function() {
	window.addEventListener('keydown', function(e) {
		if (e.target == document.body) {
			var main = document.querySelector('main');
			if (e.keyCode == 84) {
				main.classList.toggle('cinema_layout');
				main.classList.toggle('default_layout');

				var layout = document.body.classList.contains('cinema_layout') ? 'cinema_layout' : 'default_layout';
				localStorage.setItem('main-layout', layout);
			} else if (e.keyCode == 68) {
				document.body.classList.toggle('dark');

				var theme = document.body.classList.contains('dark') ? 'dark' : '';
				localStorage.setItem('main-theme', theme);
			}
		}

		function isInElement(domElement) {
			var element = e.target;
			while (element !== null) {
				if (element == domElement) {
					return true;
				}
				element = element.parentElement;
			}
			return false;
		}

		function isNotClickable(domElement) {
			if (e.target.closest('button,a,input')) return false;
			return isInElement(domElement);
		}

		e.isInElement = isInElement;

		if (e.keyCode == 38) { // UP
			this.app.emit('keydown_up', e);
		} else if (e.keyCode == 40) { // DOWN
			this.app.emit('keydown_down', e);
		} else if (e.keyCode == 32) { // SPACE
			e.isInElement = isNotClickable;
			this.app.emit('keydown_space', e);
		}
	}.bind(this), false);
};




// history.pushState({foo: "bar"}, "page name", "/file.html")
var UrlManager = function(app) {
	this.app = app;

	app.on('accountReady', function() {
		this.scanUrl();
	}.bind(this));

	app.on('room/join', function(roomData) {
		var name = roomData.name || 'No Name';
		history.pushState({}, '', '/room/' + name);
	});

	app.on('roomNameChanged', function(name) {
		if (name) {
			history.pushState({}, '', '/room/' + name);
		}
	});
};

UrlManager.moduleName = UrlManager.prototype.moduleName = 'urlManager';

TiPlayerApp.modules.push(UrlManager);

UrlManager.prototype.scanUrl = function() {
	var elms = document.location.pathname.split('/');
	elms.splice(0, 1);
	switch (elms[0]) {
	case 'login':
		this.app.emit('openLogin');
		break;
	case 'rooms':
		this.app.emit('openRoomSelector');
		break;
	case 'room':
		var roomName = elms[1];
		this.app.emit('openRoom', roomName);
		break;
	case 'accountSettings':
		this.app.emit('openAccountSettings');
		break;
	default:
		this.app.emit('openRoomSelector');
	}
};

var viewManager = {
	views: {},
	main: null,
	secondary: []
};

window.addEventListener('load', function() {
	viewManager.background_div = document.querySelector('#main_view_overlay');
	viewManager.background_div.addEventListener('click', function() {
		var topViewName = viewManager.secondary.slice(-1)[0];
		if (topViewName) {
			viewManager.hideView(topViewName);
			viewManager.hideView(topViewName);
		}
	});
});

viewManager.registerView = function(name, viewObject, url) {
	viewManager.views[name] = {
		viewObj: viewObject,
		url: url
	};
};

viewManager.displayView = function(viewName) {
	if (viewManager.isVisible(viewName)) {
		return;
	}

	if (viewManager.main === null) {
		viewManager.setView(viewName);
	} else {
		viewManager.setHoverView(viewName);
	}
};

viewManager.hideView = function(viewName) {
	var i = viewManager.secondary.indexOf(viewName);
	var view = viewManager.views[viewName];
	if (view === undefined) {
		return;
	}
	if (i > -1) {
		viewManager.secondary.splice(i, 1);
		view.viewObj.element.classList.remove('hover_view');

		var last = viewManager.secondary.slice(-1)[0];
		if (last) {
			viewManager.views[last].viewObj.show();
		}
	} else if (viewManager.main == view) {
		viewManager.main = null;
	}
	view.viewObj.hide();

	if (viewManager.secondary.length === 0) {
		viewManager.background_div.classList.add('hidden');
	}
};

viewManager.setView = function(viewName) {
	if (viewManager.main) {
		viewManager.main.viewObj.hide();
	}
	viewManager.main = viewManager.views[viewName];
	viewManager.main.viewObj.show();
	if (viewManager.views[viewName].url) {
		history.pushState({}, '', viewManager.views[viewName].url);
	}

	for (var i = viewManager.secondary.length - 1 ; i >= 0 ; i--) {
		viewManager.hideView(viewManager.secondary[i]);
	}
};

viewManager.setHoverView = function(viewName) {
	var new_view = viewManager.views[viewName];
	if (!new_view) {
		return;
	}

	var topHoverView = viewManager.secondary.slice(-1)[0];
	if (topHoverView !== undefined && topHoverView in viewManager.views) {
		viewManager.views[topHoverView].viewObj.hide();
	}

	viewManager.secondary.push(viewName);
	new_view.viewObj.element.classList.add('hover_view');

	viewManager.background_div.classList.remove('hidden');

	new_view.viewObj.show();
};

viewManager.isVisible = function(viewName) {
	return viewManager.main == viewManager.views[viewName] || viewManager.secondary.indexOf(viewName) != -1;
};

// Called when the script loaded
var googleApiClientReady = function() {
	gapi.client.setApiKey('AIzaSyBKy1DWGbPsRCn7zG07yQ3a73PHKldb_cI');
	gapi.client.load('youtube', 'v3');
	YTData.APILoaded = true;
};

var YTData = {};

YTData.APILoaded = false;

YTData.getVideoData = function(videoIDs, callback) {
	callback = callback || function() {};

	if (!YTData.APILoaded) {
		return callback('API is not loaded');
	}

	var ids = videoIDs instanceof Array ? videoIDs : [videoIDs];

	if (ids.length === 0) {
		return callback(null, []);
	}

	var request = gapi.client.youtube.videos.list({
		part: 'contentDetails,snippet,statistics',
		id: ids.join(','),
		fields: 'items(contentDetails,id,snippet,statistics)'
	});

	request.execute(function(response) {
		if (response.result.items.length === 0)
			return callback('Video not found');

		var videos = Array(response.result.items.length);
		for (var i = 0 ; i < response.result.items.length ; i++){
			videos[i] = YTData.videoFromYTDataResponse(response.result.items[i]);
		}

		videos = (videos.length === 1) ? videos[0] : videos;
		return callback(null, videos);
	});
};

YTData.getPlaylistDescription = function(playlistIDs, callback) {
	callback = callback || function() {};

	if (!YTData.APILoaded) {
		return callback('API is not loaded');
	}

	var ids = playlistIDs instanceof Array ? playlistIDs : [playlistIDs];

	if (ids.length === 0) {
		return callback(null, []);
	}

	var request = gapi.client.youtube.playlists.list({
		id: ids.join(','),
		part: 'contentDetails,id,snippet',
		fields: 'items(contentDetails,id,snippet(title, channelTitle, thumbnails(default(url))))'
	});

	request.execute(function(response) {
		response = response.result.items;
		var PlaylistDataList = [];
		for (var i = 0 ; i < response.length ; i++){
			var p = YTData.playlistInfoFromYTDataResponse(response[i]);

			PlaylistDataList.push(p);
		}
		callback(null, PlaylistDataList);
	});

};

YTData.videoFromYTDataResponse = function(data) {
	var video = {};

	if (data === undefined) {
		return {};
	}

	video.origin = 'youtube';
	video.id = data.id;
	video.thumbnail = 'https://i3.ytimg.com/vi/' + data.id + '/hqdefault.jpg';

	if (data.snippet !== undefined) {
		video.title = data.snippet.title;
		video.channelTitle = data.snippet.channelTitle;
	} else {
		video.title = '';
		video.channelTitle = '';
	}

	if (data.contentDetails !== undefined) {
		video.duration = Utils.convertYTtime(data.contentDetails.duration);
	} else {
		video.duration = 0;
	}

	if (data.statistics !== undefined) {
		var likeCount = parseInt(data.statistics.likeCount, 10);
		var unlikeCount = parseInt(data.statistics.dislikeCount, 10);
		var likeRatio = unlikeCount + likeCount === 0 ? null : Math.floor(100 * likeCount / (unlikeCount + likeCount)) / 100;

		video.viewCount = data.statistics.viewCount;
		video.likeRatio = likeRatio;
	} else {
		video.viewCount = 0;
		video.likeRatio = -1;
	}

	return video;
};

YTData.playlistInfoFromYTDataResponse = function(data) {
	return {
		thumbnail: data.snippet.thumbnails.default.url,
		id: data.id,
		title: data.snippet.title,
		channel: data.snippet.channelTitle,
		videoCount: data.contentDetails.itemCount
	};
};

var YTPlayer = {
	player: null,
	apiReady: false, // when the player is usable: video can be played. (On mobile an user click is needed before being usable)
	mobileAdvertisement: null,
	mobileAdvertisementVisible: false,
	desiredState: {
		state: 'pause',
		videoID: 'xPfMb50dsOk',
		startTime: new Date(),
		volume: 50,
		muted: false
	},
	checkStatusLoop: true,
	checkId: null,
	videoEnded: false,
	justSetVideo: false
};

EventEmitter(YTPlayer);

YTPlayer.createPlayer = function() {
	if (YTPlayer.player !== null) {
		return;
	}

	// Load YouTube Player api
	var tag = document.createElement('script');
	tag.src = window.location.protocol + '//www.youtube.com/iframe_api';
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	var useYTCookies = getYTCookieChoice();
	storeYTCookieChoice(useYTCookies);

	// Create callback called by the API
	window.onYouTubeIframeAPIReady = function(){
		console.log(localStorage.getItem('debug-showNativeControls'));
		var shouldShowNativeControls = localStorage.getItem('debug-showNativeControls') === 'true';
		YTPlayer.player = new YT.Player('player', {
			height: '390',
			width: '640',
			videoId: 'xPfMb50dsOk',
			host: useYTCookies ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com',
			playerVars: {
				'origin': document.location.origin,
				'controls': shouldShowNativeControls ? 1 : 0,
				'rel': 0,
				'showinfo': 0,
				'autohide': 1,
				'iv_load_policy': 3,
				'disablekb': 1,
				'theme': 'light',
				'autoplay': 1,
				'fs': 0
			},
			events: {
				'onReady': YTPlayer._onPlayerReady.bind(YTPlayer),
				'onStateChange': YTPlayer._onPlayerStateChange.bind(YTPlayer),
				'onError': YTPlayer._onError.bind(YTPlayer)
			}
		});

		YTPlayer.player.getVideoId = function() {
			var videoURL = YTPlayer.player.getVideoUrl();
			var regExp = /v=([\S]{11})/;
			return regExp.exec(videoURL)[1];
		};
	};

	YTPlayer.mobileAdvertisement = document.querySelector('#mobileUserPlayerAlert');

	function getYTCookieChoice() {
		return localStorage.YTCookies !== undefined ? localStorage.YTCookies : true;
	}

	function storeYTCookieChoice(YTCookiesUsed) {
		localStorage.YTCookies = YTCookiesUsed;
	}
};


YTPlayer.setVideo = function(videoID) {
	this.desiredState.videoID = videoID;
	// this.desiredState.pauseTime = 0;
	// this.desiredState.startTime = new Date();
	this.videoEnded = false;
	this.justSetVideo = true;

	if (this.apiReady && this.player.getVideoId() != videoID) {
		this.player.loadVideoById(videoID);
	}
};

YTPlayer.pause = function(time) {
	this.desiredState.state = 'pause';
	this.videoEnded = false;
	this.desiredState.pauseTime = time;
	this.syncToDesiredTime();

	if (this.apiReady) this.player.pauseVideo();

	YTPlayer.stopTimeWatcher();
};

YTPlayer.endVideo = function() {
	this.desiredState.state = 'pause';
	this.videoEnded = true;

	YTPlayer.emit('videoEnded');

	if (this.apiReady) this.player.pauseVideo();

	YTPlayer.stopTimeWatcher();
};

YTPlayer.play = function(startTime) {
	this.desiredState.state = 'play';
	this.videoEnded = false;

	if (this.apiReady) this.player.playVideo();

	if (startTime) this.desiredState.startTime = startTime;
	this.syncToDesiredTime();

	YTPlayer.startTimeWatcher();
};

YTPlayer.getExpectedTime = function() {
	var time = (new Date().getTime() + TiPlayerApp.serverTimeOffset - this.desiredState.startTime.getTime()) / 1000;
	return time;
};

YTPlayer.syncToDesiredTime = function() {
	if (!this.apiReady) return;

	if (this.desiredState.state == 'play') {
		this.player.seekTo(this.getExpectedTime());
	} else {
		this.player.seekTo(this.desiredState.pauseTime);
	}
};

YTPlayer.timeSyncIsNeeded = function() {
	var currentTime = this.player.getCurrentTime();
	var expectedTime = this.getExpectedTime();
	var acceptableSecondsDifference = 10;
	return Math.abs(currentTime - expectedTime) > acceptableSecondsDifference;
};

YTPlayer.setVolume = function(volume) {
	this.desiredState.volume = volume;

	if (this.apiReady) this.player.setVolume(volume);
};

YTPlayer.mute = function() {
	this.desiredState.muted = true;

	if (this.apiReady) this.player.mute();
};

YTPlayer.unMute = function() {
	this.desiredState.muted = false;

	if (this.apiReady) this.player.unMute();
};

YTPlayer.applyDesiredState = function() {
	YTPlayer.setVideo(YTPlayer.desiredState.videoID);
	if (YTPlayer.desiredState.state == 'play') {
		YTPlayer.play(YTPlayer.desiredState.time);
	} else if (YTPlayer.state == 'pause') {
		YTPlayer.pause(YTPlayer.desiredState.time);
	}
	YTPlayer.setVolume(YTPlayer.desiredState.volume);
	if (YTPlayer.desiredState.muted) {
		YTPlayer.mute();
	}
};

YTPlayer._onPlayerReady = function(_event) {
	if (Utils.isMobileUser()){
		YTPlayer.mobileAdvertisement.classList.remove('hidden');
		YTPlayer.mobileAdvertisementVisible = true;
	}
}.bind(this);

YTPlayer._onPlayerStateChange = function(event) {
	if (!YTPlayer.apiReady) {
		YTPlayer.apiReady = true;
		YTPlayer.applyDesiredState();
		YTPlayer.checkPlayerState();
		YTPlayer.startBufferWatcher();
	}
	if (YTPlayer.mobileAdvertisementVisible) {
		YTPlayer.mobileAdvertisementVisible = false;
		YTPlayer.mobileAdvertisement.classList.add('hidden');
	}
	switch (event.data){
	case -1: // unstarted
		YTPlayer.player.playVideo(); // force player to load some data (time / title)
		YTPlayer.syncToDesiredTime();
		break;
	case 0: // ended
		// need to stop the player only if the event is not emit after new video start
		if (YTPlayer.justSetVideo) {
			YTPlayer.syncToDesiredTime();
			return;
		}
		YTPlayer.endVideo();

		break;
	case 1: // playing
		YTPlayer.justSetVideo = false;
		if (YTPlayer.desiredState.state == 'pause'){
			YTPlayer.player.pauseVideo();
			YTPlayer.syncToDesiredTime();
		} else if (YTPlayer.timeSyncIsNeeded()) {
			// TODO: Find a better solution to prevent doing to much sync calls
			YTPlayer.syncToDesiredTime();
		}
		break;
	case 2: // paused
		YTPlayer.justSetVideo = false;
		if (YTPlayer.desiredState.state == 'play'){
			YTPlayer.player.playVideo();
			YTPlayer.syncToDesiredTime();
		}
		break;
	case 3: // buffering
		break;
	case 5: // video cued
		break;
	}
}.bind(this);

YTPlayer._onError = function(event){
	Logger.error('YouTube player error: ', event);
};

YTPlayer.checkPlayerState = function() {
	var playerState = YTPlayer.player.getPlayerState();
	if (!YTPlayer.videoEnded && playerState == 2 && YTPlayer.desiredState.state == 'play') {
		Logger.debug('force play');
		YTPlayer.player.playVideo();
	}
	else if (YTPlayer.videoEnded || playerState == 1 && YTPlayer.desiredState.state == 'pause') {
		Logger.debug('force pause');
		YTPlayer.player.pauseVideo();
	}

	if (YTPlayer.checkStatusLoop && YTPlayer.checkId === null) {
		YTPlayer.checkId = setTimeout(function() {
			YTPlayer.checkId = null;
			YTPlayer.checkPlayerState();
		}, 200);
	}
};

YTPlayer.startBufferWatcher = function() {
	setInterval(function() {
		var loadedFrac = YTPlayer.player.getVideoLoadedFraction() || 0;
		YTPlayer.emit('bufferUpdate', loadedFrac);
	}, 100);
};

YTPlayer.sendTimeUpdateEvent = function() {
	if (!YTPlayer.apiReady) return;
	var loadedFrac = YTPlayer.player.getVideoLoadedFraction() || 0;
	YTPlayer.emit('bufferUpdate', loadedFrac);
	this.emit('timeUpdate', this.player.getCurrentTime());
};

YTPlayer.startTimeWatcher = function() {
	if (YTPlayer.intervalToken) {
		clearInterval(YTPlayer.intervalToken);
		YTPlayer.intervalToken = null;
	}
	if (YTPlayer.apiReady) {
		YTPlayer.intervalToken = setInterval(function() {
			YTPlayer.sendTimeUpdateEvent();
		}, 100);
	} else {
		setTimeout(YTPlayer.startTimeWatcher, 500);
	}
};

YTPlayer.stopTimeWatcher = function() {
	clearInterval(YTPlayer.intervalToken);
	YTPlayer.intervalToken = null;
};

(function() {
	window.playlistDownloader = playlistDownloader;

	function playlistDownloader(socket, playlistId, createElement, roomPlaylist) {

		var self = this;

		var events = {};

		var playlistVideos = [];
		var chunkLoaded = [];
		var chunkSize = 20;

		var playlistInfo = {};

		var firstConnection = true;

		var emitPrefix = roomPlaylist ? 'playlist' : 'playlistTest';

		addListeners();
		bindFunctions(this);
		// EventEmitter(this); // TODO: use this or internal emitter
		this.on = on;
		this.removeListener = removeListener;

		register();

		this.createElementWithMiddleware = createMiddleware(createElement);

		this.delete = function() {
			removeListeners();
			unregister();
		};

		// ONLY FUNCTION BELOW THIS LINE, NO MORE LOGIC

		function addListeners() {
			socket.on('playlist/info', onInfo);
			socket.on('playlist/videoAdded', onVideoAdded);
			socket.on('playlist/playlistAdded', onPlaylistAdded);
			socket.on('playlist/videoRemoved', onVideoRemoved);
			socket.on('playlist/clear', onClear);
			socket.on('playlist/moveVideo', onMoveVido);
			socket.on('playlist/getElements/success', onGetElementsSuccess);
			socket.on('playlist/getElements/error', onGetElementsError);
			socket.on('playlist/titleUpdate', onTitleUpdate);

			socket.on('reconnect', onReconnect);
			// socket.on('connection/user', onReconnect);
		}

		function removeListeners() {
			socket.removeListener('playlist/info', onInfo);
			socket.removeListener('playlist/videoAdded', onVideoAdded);
			socket.removeListener('playlist/playlistAdded', onPlaylistAdded);
			socket.removeListener('playlist/videoRemoved', onVideoRemoved);
			socket.removeListener('playlist/clear', onClear);
			socket.removeListener('playlist/moveVideo', onMoveVido);
			socket.removeListener('playlist/getElements/success', onGetElementsSuccess);
			socket.removeListener('playlist/getElements/error', onGetElementsError);
			socket.removeListener('playlist/titleUpdate', onTitleUpdate);

			socket.removeListener('reconnect', onReconnect);
			// socket.removeListener('connect', onReconnect);
			// socket.removeListener('connection/user', onReconnect);
		}

		function on(evtName, callback) {
			if (events[evtName] === undefined) {
				events[evtName] = [callback];
			} else {
				events[evtName].push(callback);
			}
		}

		function emit(evtName, data) {
			if (events[evtName] !== undefined) {
				for (var i = 0 ; i < events[evtName].length ; i++) {
					events[evtName][i].call(null, data);
				}
			}
		}
		function removeListener(evtName, callback) {
			if (events[evtName] === undefined) { return; }
			var i = events[evtName].indexOf(callback);
			if (i >= 0) {
				events[evtName].splice(i, 1);
			}
		}

		function createMiddleware(oldFunc) {
			return function(data, index) {
				if (index < 0 || index >= playlistVideos.length) return null;
				var chunk = Math.floor(index / chunkSize);
				if (chunkLoaded[chunk] !== true) {
					loadChunkFromVideoPosition(index);
				}

				return oldFunc(self, data, index);
			};
		}

		function onReconnect() {
			register();
			chunkLoaded = chunkLoaded.map(function(){return false;});
			emit('dataUpdate', playlistVideos);
		}

		function getChunk(index) {
			return Math.floor(index / chunkSize);
		}

		function loadChunkFromVideoPosition(index, force) {
			if (index < 0 || index >= playlistVideos.length) return;

			var chunk = getChunk(index);
			if (chunkLoaded[chunk] && !force) return;

			chunkLoaded[chunk] = true;
			var startIndex = chunk * chunkSize;
			var endIndex = Math.min((chunk + 1) * chunkSize, playlistVideos.length);
			getElements(startIndex, endIndex);
		}

		function updateLength(length) {
			playlistVideos.splice(length);
			while (playlistVideos.length < length) {
				playlistVideos.push(undefined);
			}
			var numberChunk = Math.ceil(length / chunkSize);
			chunkLoaded.splice(numberChunk);
			while (numberChunk > chunkLoaded.length) {
				chunkLoaded.push(false);
			}
			playlistInfo.playlistLength = length;
			emit('infoUpdate', playlistInfo);
		}


		// Socket response

		function onInfo(data) {
			if (data.playlistId != playlistId) return;

			playlistInfo = data;
			updateLength(data.playlistLength);

			if (firstConnection) {
				loadChunkFromVideoPosition(0);
				firstConnection = false;
			}
			emit('infoUpdate', playlistInfo);
		}

		function onVideoAdded(data) {
			if (data.playlistId != playlistId) return;
			
			updateLength(playlistVideos.length + 1);

			var chunk = Math.floor(data.index / chunkSize);
			if (chunkLoaded[chunk]) {
				loadChunkFromVideoPosition(data.index, true);
			}
			emit('dataUpdate', playlistVideos);
		}

		function onPlaylistAdded(data) {
			if (data.playlistId != playlistId) return;
			
			updateLength(data.playlistLength);
			var from = getChunk(data.indexFrom);
			var to = getChunk(data.indexTo);
			chunkLoaded = chunkLoaded.map( function(elm, index) {
				if (index < from || index > to) {
					return elm;
				}
				return false;
			}.bind(this));
			emit('dataUpdate', playlistVideos);
		}

		function onVideoRemoved(data) {
			if (data.playlistId != playlistId) return;

			playlistVideos.splice(data.index, 1);
			updateLength(playlistVideos.length);
			emit('dataUpdate', playlistVideos);
		}

		function onClear(data) {
			if (data.playlistId != playlistId) return;

			playlistVideos = [];
			chunkLoaded = [];
			emit('dataUpdate', playlistVideos);
			playlistInfo.playlistLength = 0;
			emit('infoUpdate', playlistInfo);
		}

		function onMoveVido(data) {
			if (data.playlistId != playlistId) return;

			var chunkFrom = getChunk(data.indexFrom);
			var chunkTo = getChunk(data.indexTo);
			if (chunkLoaded[chunkFrom] && chunkLoaded[chunkTo]) {
				var tmp = playlistVideos[data.indexFrom];
				playlistVideos[data.indexFrom] = playlistVideos[data.indexTo];
				playlistVideos[data.indexTo] = tmp;
				// virtualScrollController.onDataUpdate(playlistVideos);
				emit('dataUpdate', playlistVideos);
			} else {
				if (chunkLoaded[chunkFrom]) {
					loadChunkFromVideoPosition(data.indexFrom, true);
				}
				if (chunkLoaded[chunkTo]) {
					loadChunkFromVideoPosition(data.indexTo, true);
				}
			}
		}


		function onGetElementsSuccess(data) {
			if (data.playlistId != playlistId) return;
			for (var i = 0, pos = data.indexFrom ; pos < data.indexTo ; i++, pos++) {
				playlistVideos[pos] = data.elements[i];
			}
			var chunkFrom = getChunk(data.indexFrom);
			var chunkTo = getChunk(data.indexTo - 1);

			for (; chunkFrom <= chunkTo ; chunkFrom++) {
				chunkLoaded[chunkFrom] = true;
			}
			emit('dataUpdate', playlistVideos);
		}

		function onGetElementsError(data) {
			if (data.playlistId != playlistId) return;
			Logger.error('Getting playlist elements failed', data);
		}

		function onTitleUpdate(data) {
			if (data.playlistId != playlistId) return;
			emit('titleUpdate', data.title);
		}

		function bindFunctions(object) {
			object.register = register;
			object.unregister = unregister;
			object.getElements = getElements;
			object.addVideo = addVideo;
			object.addPlaylist = addPlaylist;
			object.move = move;
			object.remove = remove;
			object.clear = clear;
		}

		// Socket controls (request) 
		function register() {
			socket.emit('playlist/register', {playlistId: playlistId});
		}
		function unregister() {
			socket.emit('playlist/unregister', {playlistId: playlistId});
		}
		function getElements(from, to) {
			socket.emit('playlist/getElements', {playlistId: playlistId, indexFrom: from, indexTo: to});
		}
		function addVideo(videoID) {
			socket.emit('playlist/addVideo', {playlistId: playlistId, videoID: videoID, origin: 'youtube'});
		}
		function addPlaylist(YTPlaylistId) {
			socket.emit('playlist/addPlaylist', {playlistId: playlistId, YTPlaylistId: YTPlaylistId, origin: 'youtube'});
		}
		function move(from, to) {
			socket.emit('playlist/moveVideo', {playlistId: playlistId, indexFrom: from, indexTo: to});
		}
		function remove(index) {
			socket.emit('playlist/removeVideo', {playlistId: playlistId, index: index});
		}
		function clear() {
			socket.emit('playlist/clear', {playlistId: playlistId});
		}
	}
})(window);

var ScrollingTextLib = {};

(function(ScrollingTextLib) {
	ScrollingTextLib.add = addScrollingText;
	ScrollingTextLib.remove = removeScrollingText;
	ScrollingTextLib.disable = disable;
	ScrollingTextLib.enable = enable;

	// internal variables
	var lastId = 0;
	var watchedElements = {};

	var enabled = true;

	// listen resize event to update container size, add a delay of 1s before updating all wathced elements on resize
	window.addEventListener('resize', _defer(_updateAll, 100));

	// Add class to the element, set the css variable coresponding to the width of the parent element needed for the animation
	// If the element was already added to the lib, it will be updated
	function addScrollingText(textElement) {
		if (textElement === null) {
			return console.error('The element cannot be null');
		}
		// If the element is not in the DOM, we wait its insertion
		if (!_isInDOM(textElement)) {
			_whenInDOM(textElement, function() {
				addScrollingText(textElement);
			});
			return;
		}
		if (textElement.parentElement === null) {
			return console.error('The element needs to have a parent');
		}
		if (textElement._stlid !== undefined) {
			_updateCssVariable(textElement);
			textElement.classList.remove('stl-scrolling-text');
			if (enabled) textElement.classList.add('stl-scrolling-text');
			return;
		}

		var id = lastId++;
		watchedElements[id] = textElement;
		textElement._stlid = id;

		_updateCssVariable(textElement);
		if (enabled) textElement.classList.add('stl-scrolling-text');
		textElement.parentElement.classList.add('stl-scrolling-text-container');
	}

	function removeScrollingText(textElement) {
		if (textElement._stlid) {
			watchedElements[textElement._stlid].classList.remove('stl-scrolling-text');
			if (watchedElements[textElement._stlid].parentElement) {
				watchedElements[textElement._stlid].parentElement.classList.remove('stl-scrolling-text-container');
			}
			delete watchedElements[textElement._stlid];
		}
	}

	function _updateCssVariable(textElement) {
		textElement.parentElement.style.setProperty('--stl-container-size', textElement.parentElement.clientWidth + 'px');
	}

	function _updateAll() {
		if (!enabled) return;
		var toRemove = [];
		for (var id in watchedElements) {
			var textElement = watchedElements[id];
			textElement.classList.remove('stl-scrolling-text');
			if (_isInDOM(textElement)) {
				_updateCssVariable(textElement);
				textElement.classList.add('stl-scrolling-text');
			} else {
				// If the element is no longer in the DOM, we remove it
				toRemove.push(id);
			}
		}
		// remove old elements
		for (var i = 0 ; i < toRemove.length ; i++) {
			delete watchedElements[toRemove[i]];
		}
	}

	function _isInDOM(element) {
		while (element !== null && element != document.body) {
			element = element.parentElement;
		}
		return element !== null;
	}

	function _whenInDOM(element, callback) {
		var intervalId = setInterval(function() {
			if (_isInDOM(element)) {
				clearInterval(intervalId);
				callback();
			}
		}, 500);
	}

	function _defer(callback, delay) {
		var timeoutId;
		return function() {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(callback, delay);
		};
	}

	function enable() {
		enabled = true;
		_updateAll();
	}

	function disable() {
		enabled = false;
		for (var key in watchedElements) {
			watchedElements[key].classList.remove('stl-scrolling-text');
		}
	}

})(ScrollingTextLib);

(function(window) {

	window.VirtualScroll = {};

	window.VirtualScroll.create = create;
	/**
	 * Initiate the virtual scroll list and create a controller for it
	 * @param  {DOMElement} listElm            The element where the list will be displayed. Needs to have a height set, can be updated with controller
	 * @param  {Array} dataList                data given to createFunction
	 * @param  {function} createElement        Create an element if data correct, return null else
	 * @param  {Number} elementHeight          The height of list item. Needs to be fixed, else the items will overlapse
	 * @param  [Number] hiddenBufferedElements The number of elements not visible that will be keep for less data update
	 * @return {Object}                        Return a controller for the virtualScroll
	 */
	function create(listElm, dataList, createElement, elementHeight, hiddenBufferedElements) {
		var divContainer = document.createElement('div');
		divContainer.style.position = 'relative';
		divContainer.style.overflow = 'hidden';
		listElm.appendChild(divContainer);

		var lastListElmHeight = listElm.clientHeight;
		var displayedElements = 1 + Math.ceil(listElm.clientHeight / elementHeight);
		if (hiddenBufferedElements && hiddenBufferedElements < 0) throw new Error('the argument hiddenBufferedElements needs to be a positive or null integer if given');
		var outsideElements = hiddenBufferedElements || 0;
		var neededElements = displayedElements + outsideElements;

		var elementsArray = new Array(neededElements);
		var arrayPointer = 0;

		var scrollTop = 0;
		var maxScrollTop;

		var firstIndexDisplayed, lastIndexDisplayed;

		listElm.addEventListener('scroll', onScrollUpdate);
		window.addEventListener('resize', onWindowResize);
		onDataUpdate();

		var controller = {};
		controller.onDataUpdate = onDataUpdate;
		controller.scrollToPos = scrollToPos;
		controller.onResize = onWindowResize;
		controller.destroy = destroy;

		function destroy() {
			divContainer.remove();
			listElm.removeEventListener('scroll', onScrollUpdate);
			window.removeEventListener('resize', onWindowResize);
			controller.onDataUpdate = undefined;
			controller.scrollToPos = undefined;
			controller.onResize = undefined;
			controller.destroy = undefined;
		}

		return controller;

		function onDataUpdate(data, hiddenBufferedElements) {
			if (hiddenBufferedElements !== undefined) {
				if (hiddenBufferedElements < 0) throw new Error('the argument hiddenBufferedElements needs to be a positive or null integer if given');
				outsideElements = hiddenBufferedElements || 0;
				neededElements = displayedElements + outsideElements;
				elementsArray = new Array(neededElements);
				arrayPointer = 0;				
			}

			dataList = data !== undefined ? data : dataList;
			divContainer.style.height = elementHeight * dataList.length + 'px';

			maxScrollTop = divContainer.clientHeight - listElm.clientHeight - elementHeight;
			maxScrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
			if (listElm.scrollTop >= maxScrollTop) {
				scrollTop = maxScrollTop;
			}

			refreshAllData();
		}

		function onWindowResize() {
			if (lastListElmHeight == listElm.clientHeight) return;

			lastListElmHeight = listElm.clientHeight;

			maxScrollTop = divContainer.clientHeight - listElm.clientHeight - elementHeight;
			maxScrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
			if (listElm.scrollTop >= maxScrollTop) {
				scrollTop = maxScrollTop;
			}
			displayedElements = 1 + Math.ceil(listElm.clientHeight / elementHeight);
			outsideElements = hiddenBufferedElements || (displayedElements << 1);
			neededElements = displayedElements + outsideElements;

			clearElements();

			elementsArray = new Array(neededElements);
			arrayPointer = 0;
			refreshAllData();
		}

		function onScrollUpdate() {
			scrollTop = listElm.scrollTop;
			if (scrollTop < 0) scrollTop = 0;
			else if (scrollTop >= maxScrollTop) scrollTop = maxScrollTop;

			var firstVisible = Math.floor(scrollTop / elementHeight);
			var lastVisible = firstVisible + displayedElements - 1;

			if (firstVisible < firstIndexDisplayed || lastVisible > lastIndexDisplayed) {
				displayDataFrom(firstVisible);
			}

			return;
		}

		function displayDataFrom(indexVisibleFrom) {
			var toUpdate = 0;
			var indexVisibleTo = indexVisibleFrom + displayedElements - 1;
			if (indexVisibleFrom < firstIndexDisplayed) {
				toUpdate -= firstIndexDisplayed - indexVisibleFrom + (outsideElements >> 1);
			} else if (indexVisibleTo > lastIndexDisplayed) {
				toUpdate += indexVisibleTo - lastIndexDisplayed + (outsideElements >> 1);
			} else return;

			if (toUpdate >= neededElements || -toUpdate >= neededElements) {
				return refreshAllData();
			}

			var i, pos, elm;
			if (toUpdate > 0) {
				for (i = 0, pos = lastIndexDisplayed + 1 ; i < toUpdate ; i++, pos++) {
					elm = createElement(dataList[pos], pos, dataList);
					addDataElementAfter(elm, pos * elementHeight);
				}
			} else {
				for (i = 0, pos = firstIndexDisplayed - 1 ; i < -toUpdate ; i++, pos--) {
					elm = createElement(dataList[pos], pos, dataList);
					addDataElementBefore(elm, pos * elementHeight);
				}
			}

			firstIndexDisplayed += toUpdate;
			lastIndexDisplayed += toUpdate;
		}

		function refreshAllData() {
			var from = Math.floor(scrollTop / elementHeight) - (outsideElements >> 1);
			var i = 0, elm;

			firstIndexDisplayed = from;
			lastIndexDisplayed = from + neededElements - 1;

			clearElements();

			for (var length = dataList.length, fromTop = from * elementHeight ;
				i < neededElements && from < length ;
				i++, from++, fromTop += elementHeight)
			{
				elm = createElement(dataList[from], from, dataList);
				addDataElementAfter(elm, fromTop);
			}
		}

		function addDataElementAfter(elm, fromTop) {
			if (elm) {
				elm.style.position = 'absolute';
				// elm.style.transform = 'translateY('+fromTop+'px)';
				elm.style.top = fromTop + 'px';
				var old = elementsArray[arrayPointer];
				if (old) {
					divContainer.replaceChild(elm, old);
				} else {
					divContainer.appendChild(elm);
				}
			} else if (elementsArray[arrayPointer]) {
				elementsArray[arrayPointer].remove();	
			}
			elementsArray[arrayPointer] = elm;
			arrayPointer = (arrayPointer + 1) % elementsArray.length;
		}

		function addDataElementBefore(elm, fromTop) {
			if (arrayPointer === 0) arrayPointer = elementsArray.length - 1;
			else arrayPointer -= 1;
			if (elm) {
				elm.style.position = 'absolute';
				// elm.style.transform = 'translateY('+fromTop+'px)';
				elm.style.top = fromTop + 'px';

				var old = elementsArray[arrayPointer];
				if (old) {
					divContainer.replaceChild(elm, old);
				} else {
					divContainer.appendChild(elm);
				}
			} else if (elementsArray[arrayPointer]) {
				elementsArray[arrayPointer].remove();	
			}
			elementsArray[arrayPointer] = elm;
		}

		function clearElements() {
			for (var i = 0 ; i < elementsArray.length ; i++) {
				if (!elementsArray[i]) continue;
				elementsArray[i].remove();
				elementsArray[i] = null;
			}
		}

		function scrollToPos(pos) {
			listElm.scrollTop = pos * elementHeight;
		}
	}
})(window);

var AccountView = function AccountView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#accountHeader');

	this.$profilePicture = this.element.querySelector('.user_picture');
	this.$username = this.element.querySelector('.username');

	this.accountControls = {
		$darkModeButton: this.element.querySelector('.dark_mode_button'),
		$settingsButton: this.element.querySelector('.settings_button'),
		$changAccountButton: this.element.querySelector('.change_account_button'),
		$logoutButton: this.element.querySelector('.logout_button'),
		$openplaylists: this.element.querySelector('.open_personal_playlists')
	};

	this._bindEvents();
};

AccountView.prototype = new View();

AccountView.prototype._bindEvents = function() {
	var that = this;
	this.accountControls.$darkModeButton.addEventListener('click', function() {
		that.controller.toogleDarkMode();
	});
	this.accountControls.$settingsButton.addEventListener('click', function() {
		that.controller.displayAccountSettings();
	});
	this.accountControls.$changAccountButton.addEventListener('click', function() {
		that.controller.displayAccountLogin();
	});
	this.accountControls.$logoutButton.addEventListener('click', function() {
		that.controller.accountLogout();
	});
	this.accountControls.$openplaylists.addEventListener('click', function() {
		that.controller.displayPersonalsPlaylist();
	});
};

AccountView.prototype.setUserInformations = function(userData) {
	this.$username.title = userData.username;
	this.$username.innerHTML = userData.visibleName;
	this.$profilePicture.src = userData.profilePicture;
	if (userData.visitor == true) {
		this.accountControls.$settingsButton.parentElement.classList.add('hidden');
		this.accountControls.$openplaylists.parentElement.classList.add('hidden');
		this.accountControls.$logoutButton.parentElement.classList.add('hidden');
	} else {
		this.accountControls.$settingsButton.parentElement.classList.remove('hidden');
		this.accountControls.$openplaylists.parentElement.classList.remove('hidden');
		this.accountControls.$logoutButton.parentElement.classList.remove('hidden');
	}
};

var Account = function Account(app) {
	this.app = app;

	this.userData = null;

	app.on('initialized', function() {
		this.headerView = new AccountView(this);
	}.bind(this));

	app.on('accountReady', function(userData) {
		this.userData = userData;
		this.headerView.setUserInformations(userData);
	}.bind(this));

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));
};

Account.moduleName = Account.prototype.moduleName = 'account';

TiPlayerApp.modules.push(Account);

Account.prototype.listenSocket = function(socket) {
	socket.on('userData', function(userData) {
		this.headerView.setUserInformations(userData);
	}.bind(this));
};

Account.prototype.toogleDarkMode = function() {
	this.app.emit('toogleDarkMode');
};

Account.prototype.displayAccountSettings = function() {
	this.app.emit('openAccountSettings');
};

Account.prototype.displayAccountLogin = function() {
	this.app.emit('openLogin');
};


Account.prototype.displayPersonalsPlaylist = function() {
	this.app.emit('openPersonalsPlaylist');
};

Account.prototype.accountLogout = function() {
	window.location = '/logout';
};

var AccountCreationView = function AccountCreationView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#accountCreationSection');


	this.$creationForm = this.element.querySelector('#accountCreationForm');

	this.$usernameInput = this.$creationForm.querySelector('input[name="username"]');
	this.$passwordInput = this.$creationForm.querySelector('input[name="password"]');
	this.$passwordConfirmationInput = this.$creationForm.querySelector('input[name="password_confirm"]');

	this.$errorMessage = this.$creationForm.querySelector('.message_error');

	this.$closeButton = this.element.querySelector('.close_section_btn');

	this._bindEvents();

	viewManager.registerView('createAccount', this, '/createAccount');
};

AccountCreationView.prototype = new View();

AccountCreationView.prototype._bindEvents = function() {
	var that = this;
	this.$creationForm.addEventListener('submit', function(e) {
		e.stopPropagation();
		e.preventDefault();

		var username = that.$usernameInput.value;
		var password = that.$passwordInput.value;
		var passwordConfirm = that.$passwordConfirmationInput.value;

		// TODO: check data ?

		that.controller.createAccount(username, password, passwordConfirm);
	});


	this.$closeButton.addEventListener('click', function() {
		viewManager.hideView('createAccount');
	});
};

AccountCreationView.prototype.errorMessage = function(message) {
	if (message) {
		this.$errorMessage.classList.remove('hidden');
	} else {
		this.$errorMessage.classList.add('hidden');
	}
	this.$errorMessage.innerHTML = message;
};

var AccountCreation = function AccountCreation(app) {
	this.app = app;

	app.on('initialized', function() {
		this.view = new AccountCreationView(this);
	}.bind(this));

	app.on('openAccountCreation', function() {
		viewManager.displayView('createAccount');
	}.bind(this));
};

AccountCreation.moduleName = AccountCreation.prototype.moduleName = 'accountCreation';

TiPlayerApp.modules.push(AccountCreation);

AccountCreation.prototype.createAccount = function(username, password, passwordConfirm) {
	if (password != passwordConfirm) {
		return this.view.errorMessage('Les mots de passe sont différents');
	}
	this.view.errorMessage('');
	this.app.socket.emitWithResponse('login/create', {username: username, password: password}, function(err, _response) {
		if (err) {
			this.view.errorMessage(err || 'Impossible de créer le compte');
			return;
		}
		viewManager.hideView('createAccount');
	}.bind(this));
};

var AccountSettings = function AccountSettings(app) {
	this.app = app;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new AccountSettingsView(this);
	}.bind(this));


	app.on('accountReady', function(userInfo) {
		this.view.updateUserData(userInfo);
	}.bind(this));

	app.on('openAccountSettings', function() {
		viewManager.displayView('accountSettings');
	}.bind(this));
};

AccountSettings.moduleName = AccountSettings.prototype.moduleName = 'accountSettings';

TiPlayerApp.modules.push(AccountSettings);

AccountSettings.prototype.listenSocket = function(socket) {
	socket.on('userData', function(userData) {
		this.view.updateUserData(userData);
	}.bind(this));
};

// Socket commands
AccountSettings.prototype.setUserData = function(visibleName, avatarURL) {
	this.app.socket.emitWithResponse('account/configure', {visibleName: visibleName, profilePicture: avatarURL}, function(err, _response) {
		if (err) {
			alert(err);
			return;
		}
		viewManager.hideView('accountSettings');
	});
};

var AccountSettingsView = function AccountSettingsView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#accountSettingsSection');

	this.$visitorInfo = this.element.querySelector('.visitor_info');

	this.$usernameSpan = this.element.querySelector('.user_info .username');
	this.$userIdSpan = this.element.querySelector('.user_info .user_id');
	this.$profilePicture = this.element.querySelector('.user_info #avatarImg');

	this.$visibleNameInput = this.element.querySelector('#usernameInput');
	this.$avatarURLInput = this.element.querySelector('#avataSrcInput');

	this.$confirmButton = this.element.querySelector('#saveChangeButton');
	this.$resetButton = this.element.querySelector('#resetButton');

	this.$closeButton = this.element.querySelector('.close_section_btn');

	this.saveData = {
		visibleName: '',
		avatarURL: ''
	};

	this._bindEvents();

	viewManager.registerView('accountSettings', this, '/accountSettings');
};

AccountSettingsView.prototype = new View();

AccountSettingsView.prototype._bindEvents = function() {
	var that = this;
	this.$confirmButton.addEventListener('click', function() {
		var visibleName = that.$visibleNameInput.value;
		var avatarURL = that.$avatarURLInput.value;
		that.controller.setUserData(visibleName, avatarURL);
	});
	this.$resetButton.addEventListener('click', function() {
		that.$avatarURLInput.value = ' ';
		that.$avatarURLInput.value = that.saveData.avatarURL;
		that.$visibleNameInput.value = ' ';
		that.$visibleNameInput.value = that.saveData.visibleName;
	});

	this.$closeButton.addEventListener('click', function() {
		viewManager.hideView('accountSettings');
	});
};


AccountSettingsView.prototype.updateUserData = function(userInfo) {
	/*grde, id, profilePicture, username, visibleName, visitor*/

	if (userInfo.visitor === true) {
		this.$visitorInfo.classList.remove('hidden');
	} else {
		this.$visitorInfo.classList.add('hidden');
	}

	if (userInfo.id !== undefined) {
		this.$userIdSpan.innerHTML = '#' + userInfo.id;
	}
	if (userInfo.profilePicture !== undefined) {
		this.saveData.avatarURL = userInfo.profilePicture;

		this.$profilePicture.src = userInfo.profilePicture;
		this.$avatarURLInput.value = userInfo.profilePicture;
	}
	if (userInfo.visibleName !== undefined) {
		this.saveData.visibleName = userInfo.visibleName;

		this.$visibleNameInput.value = userInfo.visibleName;
	}
	if (userInfo.username !== undefined) {
		this.$usernameSpan.innerHTML = userInfo.username;
	}
};

var ConnectionSatutView = function ConnectionSatutView(controller) {
	this.controller = controller;

	this.$statusDiv = document.querySelector('#stateConnection');

	// preload class for differents status
	this.updateStatus(0);
	this.updateStatus(1);
	this.updateStatus(2);

};

ConnectionSatutView.prototype = new View();

ConnectionSatutView.prototype.updateStatus = function(status) {
	this.$statusDiv.querySelectorAll('i').forEach(function(elm) { elm.classList.remove('selected'); });

	switch (status){
	case 0:
		this.$statusDiv.children[0].classList.add('selected');
		break;
	case 1:
		this.$statusDiv.children[1].classList.add('selected');
		break;
	case 2:
		this.$statusDiv.children[2].classList.add('selected');
		break;
	}
};

var ConnectionStatus = function ConnectionStatus(app) {
	this.app = app;

	app.on('initialized', function() {
		this.view = new ConnectionSatutView(this);
	}.bind(this));

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));
};

ConnectionStatus.moduleName = ConnectionStatus.prototype.moduleName = 'connectionStatus';

TiPlayerApp.modules.push(ConnectionStatus);

ConnectionStatus.prototype.listenSocket = function(socket) {
	var that = this;
	socket.on('connect', function() {
		that.app.emit('socket/connected');
		that.view.updateStatus(0);
	});

	socket.on('disconnect', function() {
		Logger.debug('disconnect');
		that.app.emit('socket/disconnected');
		that.view.updateStatus(1);
	});

	// Connection errors events
	socket.on('connect_error', function() {
		Logger.debug('connect_error');
		that.app.emit('socket/connectError');
		that.view.updateStatus(1);
	});
	socket.on('connect_timeout', function() {
		Logger.debug('connect_timeout');
		that.app.emit('socket/connectionTimeout');
		that.view.updateStatus(1);
	});
	socket.on('reconnect_attempt', function() {
		Logger.debug('reconnect_attempt');
		that.app.emit('socket/reconnetAttempt');
		that.view.updateStatus(2);
	});
	socket.on('reconnect', function() {
		Logger.debug('reconnect');
		that.app.emit('socket/reconnect');
		that.view.updateStatus(0);
	});
};

var LoginView = function LoginView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#loginSection');

	this.$guestLoginProposal = this.element.querySelector('#guestLoginProposal');
	this.$continueAsGuestButton = this.$guestLoginProposal.querySelector('button');

	this.$loginForm = this.element.querySelector('#loginForm');

	this.$loginInput = this.$loginForm.querySelector('input[name="username"]');
	this.$passwordInput = this.$loginForm.querySelector('input[name="password"]');

	this.$errorMessage = this.$loginForm.querySelector('.message_error');

	this.$closeButton = this.element.querySelector('.close_section_btn');

	this.$creationAccountButton = this.element.querySelector('#accountCreationButton');

	this.$discordSignInButton = this.element.querySelector('.discord_sign_in');
	this.$discordSignInLoader = this.element.querySelector('.discord_sign_in .loader');

	this._bindEvents();

	viewManager.registerView('login', this, '/login');
};

LoginView.prototype = new View();

LoginView.prototype._bindEvents = function() {
	var that = this;

	this.$continueAsGuestButton.addEventListener('click', function() {
		that.controller.continueAsGuest();
	});

	this.$loginForm.addEventListener('submit', function(e) {
		e.stopPropagation();
		e.preventDefault();

		var username = that.$loginInput.value;
		var password = that.$passwordInput.value;

		// TODO: check data ?

		that.controller.loginIn(username, password);

	});

	this.$closeButton.addEventListener('click', function() {
		viewManager.hideView('login');
	});

	this.$creationAccountButton.addEventListener('click', function(){
		that.controller.displayAccountCreation();
	});

	this.$discordSignInButton.addEventListener('click', function() {
		that.controller.discordSignIn();
	});
};

LoginView.prototype.errorMessage = function(message) {
	this.$errorMessage.innerHTML = message;
	this.$errorMessage.classList.remove('hidden');
	setTimeout(function() {
		this.$errorMessage.classList.add('hidden');
	}.bind(this), 5000);
};

LoginView.prototype.showDiscordLoader = function() {
	this.$discordSignInLoader.classList.remove('hidden');
};

LoginView.prototype.hideDiscordLoader = function() {
	this.$discordSignInLoader.classList.add('hidden');
};

LoginView.prototype.showGuestLoginOption = function() {
	this.$guestLoginProposal.classList.remove('hidden');
};

LoginView.prototype.hideGuestLoginOption = function() {
	this.$guestLoginProposal.classList.add('hidden');
};

var OAuthAccountConfiguration = function OAuthAccountConfiguration(app) {
	this.app = app;
	this.currentOAuthData = null;

	app.on('initialized', function() {
		this.view = new OAuthAccountConfigurationView(this);
	}.bind(this));

	app.on('openOAuthAccountConfiguration', function(oAuthData) {
		this.currentOAuthData = oAuthData;
		this.view.configureWith(oAuthData);
		viewManager.displayView('oAuthAccountConfiguration');
	}.bind(this));
};

OAuthAccountConfiguration.moduleName = OAuthAccountConfiguration.prototype.moduleName = 'oAuthAccountConfiguration';

TiPlayerApp.modules.push(OAuthAccountConfiguration);

OAuthAccountConfiguration.prototype.createWithInformations = function(username) {
	if (!this.currentOAuthData) { return; }
	this.app.socket.emitWithResponse(
		'oAuth/createAccount',
		{username: username, token: this.currentOAuthData.token},
		function(err, _response) {
			console.log('=======');
			console.log(err, _response);
			if (err) {
				console.log(err);
				this.view.errorMessage(err);
				return;
			}
			viewManager.hideView('oAuthAccountConfiguration');
		}.bind(this)
	);
};

var OAuthAccountConfigurationView = function OAuthAccountConfigurationView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#oAuthAccountConfigurationSection');

	this.$configurationForm = this.element.querySelector('#oAuthAccountConfigurationForm');
	this.$avatarImage = this.element.querySelector('.avatar_image');

	this.$usernameInput = this.$configurationForm.querySelector('input[name="username"]');
	this.$tokenInput = this.$configurationForm.querySelector('input[name="token"]');

	this.$errorMessage = this.$configurationForm.querySelector('.message_error');

	this.$closeButton = this.element.querySelector('.close_section_btn');

	this._bindEvents();

	viewManager.registerView('oAuthAccountConfiguration', this, '/oauth/configuration');
};

OAuthAccountConfigurationView.prototype = new View();

OAuthAccountConfigurationView.prototype._bindEvents = function() {
	var that = this;

	this.$configurationForm.addEventListener('submit', function(e) {
		e.stopPropagation();
		e.preventDefault();

		var username = that.$usernameInput.value;
		that.controller.createWithInformations(username);
	});

	this.$closeButton.addEventListener('click', function() {
		viewManager.hideView('oAuthAccountConfiguration');
	});
};

OAuthAccountConfigurationView.prototype.configureWith = function(oAuthInformations) {
	this.$usernameInput.value = oAuthInformations.username;
	this.$avatarImage.src = oAuthInformations.avatarURL;
};

OAuthAccountConfigurationView.prototype.errorMessage = function(message) {
	this.$errorMessage.innerHTML = message;
	this.$errorMessage.classList.remove('hidden');
	setTimeout(function() {
		this.$errorMessage.classList.add('hidden');
	}.bind(this), 5000);
};

var Login = function Login(app) {
	this.app = app;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new LoginView(this);
	}.bind(this));

	app.on('openLogin', function(data) {
		if (data && data.cause == 'visitorFirstAppearance') {
			this.view.showGuestLoginOption();
		} else {
			this.view.hideGuestLoginOption();
		}
		viewManager.displayView('login');
	}.bind(this));
};

Login.moduleName = Login.prototype.moduleName = 'login';

TiPlayerApp.modules.push(Login);

Login.prototype.listenSocket = function(socket) {
	socket.on('connection/user', function(user) {
		Logger.debug(user);

		this.app.emit('accountReady', user);

		if (user.visitor === true) {
			setTimeout(function() {
				this.app.emit('openLogin', {cause: 'visitorFirstAppearance'});
			}.bind(this), 1000);
		}
	}.bind(this));
};

Login.prototype.continueAsGuest = function() {
	viewManager.hideView('login');
};

Login.prototype.loginIn = function(username, password) {
	this.app.socket.emitWithResponse('login/login', {username: username, password: password}, function(err, _response) {
		if (err) {
			this.view.errorMessage('combinaison incorrecte');
			return;
		}
		viewManager.hideView('login');
	}.bind(this));
};

Login.prototype.displayAccountCreation = function() {
	viewManager.hideView('login');
	this.app.emit('openAccountCreation');
};

Login.prototype.discordSignIn = function() {
	this.view.showDiscordLoader();
	this.app.socket.emit('oAuth/listening', 'discord');
	this.app.socket.once('oAuth/login/success', function() {
		this.view.hideDiscordLoader();
		viewManager.hideView('login');
	}.bind(this));
	this.app.socket.once('oAuth/login/requireAccountCreation', function(informations) {
		this.view.hideDiscordLoader();
		viewManager.hideView('login');
		this.app.emit('openOAuthAccountConfiguration', informations);
	}.bind(this));
	Utils.popupCenter(window.location.origin + '/auth/discord', '', 850, 800);
};

var PersonalsPlaylistView = function PersonalsPlaylistView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#personalPlaylistSection');

	this.$closeButton = this.element.querySelector('.close_section_btn');
	this.$createPlaylistButton = this.element.querySelector('.create_playlist');
	var playlistList = this.$playlistList = this.element.querySelector('#personalPlaylistList');
	var videoTemplate = Handlebars.compile(this.element.querySelector('#personal-playlist-video-template').innerHTML);


	this._bindEvents();

	viewManager.registerView('personalsPlaylist', this, '/personalsPlaylist');


	var that = this;
	var controllers = [];
	var socket = this.controller.app.socket;

	this.addPlaylistToList = addPlaylistToList;
	this.removePlaylistSection = removePlaylistSection;
	this.clearList = clearList;


	function addPlaylistToList(playlistId) {
		var elm = document.createElement('div');
		elm.classList.add('playlistSection');

		elm.dataset.playlistId = playlistId;

		var elmList = document.createElement('ol');
		elmList.classList.add('playlist');


		elm.appendChild(elmList);
		playlistList.appendChild(elm);

		var controller = new playlistDownloader(socket, playlistId, createElement);

		var virtualScrollController = VirtualScroll.create(
			elmList,
			[],
			controller.createElementWithMiddleware,
			45, // height
			20 // hidden elements kept in memory
		);
		controller.virtualScrollController = virtualScrollController;

		controller.on('dataUpdate', function(playlist) {
			virtualScrollController.onDataUpdate(playlist);
		});

		elm.insertBefore(getControlsElm(playlistId, controller), elmList);


		controllers.push(controller);
	}

	function removePlaylistSection(playlistId) {
		for (var i = playlistList.children.length - 1 ; i >= 0 ; i--) {
			var elm = playlistList.children[i];
			if (elm.dataset.playlistId == playlistId) {
				controllers[i].virtualScrollController.destroy();
				controllers[i].delete();
				controllers.splice(i, 1);
				elm.remove();
			}
		}
	}

	function clearList() {
		for (var i = playlistList.children.length - 1 ; i >= 0 ; i--) {
			var elm = playlistList.children[i];
			controllers[i].virtualScrollController.destroy();
			controllers[i].delete();
			controllers.splice(i, 1);
			elm.remove();
		}
	}

	function getControlsElm(playlistId, controller) {
		var elm = document.createElement('div');
		elm.classList.add('controls');

		var input = document.createElement('input');
		input.placeholder = 'Playlist title';

		var timeoutId;

		input.addEventListener('input', function(_event) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(that.controller.changePlaylistTitle.bind(that.controller, playlistId, input.value), 1000);
		});

		elm.appendChild(input);

		var text = document.createElement('span');
		text.innerHTML = playlistId;
		elm.appendChild(text);

		var _playlistTitle, _playlistLength, _playlistId;

		controller.on('infoUpdate', function(data) {
			_playlistTitle = data.title || data.title;
			_playlistLength = data.playlistLength || data.playlistLength;
			_playlistId = data.playlistId || data.playlistId;
			text.innerHTML = _playlistTitle + ' (' + _playlistLength + ') #' + _playlistId;
			input.value = _playlistTitle;
		});

		controller.on('titleUpdate', function(title) {
			_playlistTitle = title;
			text.innerHTML = _playlistTitle + ' (' + _playlistLength + ') #' + _playlistId;
			input.value = _playlistTitle;
		});

		elm.appendChild(document.createElement('br'));

		var button = document.createElement('button');
		button.innerHTML = 'delete';
		button.addEventListener('click', function() {
			if (confirm('Êtes vous sûr de vouloir supprimer la playlist "' + _playlistTitle + '" (#' + _playlistId + ') ? Cette action est définitive')) {
				that.controller.deletePersonalPlaylist(playlistId);
			}
		});
		elm.appendChild(button);


		button = document.createElement('button');
		button.innerHTML = 'Clear';
		button.addEventListener('click', function() {
			if (confirm('Êtes vous sûr de vouloir vider la playlist "' + _playlistTitle + '" (#' + _playlistId + ') ? Cette action est définitive')) {
				controller.clear();
			}
		});
		elm.appendChild(button);


		return elm;
	}

	function createElement(controller, data, index) {
		if (data === undefined || data === null) return null;

		var parent = document.createElement('div');
		parent.innerHTML = videoTemplate({
			videoID: data.videoID,
			title: data.title,
			channel:data.channelTitle,
			time: Utils.stringifyTime(data.duration)
		});

		// window.parent = parent;
		var elm = parent.firstElementChild;

		elm.querySelector('button.youtube').addEventListener('click', function(e) {
			e.stopPropagation();
			var url = 'https://www.youtube.com/watch/' + data.videoID;
			var win = window.open(url, '_blank');
			win.focus();
		});

		elm.querySelector('button.move_up').addEventListener('click', function(e) {
			e.stopPropagation();
			controller.move(index, index - 1);
		});

		elm.querySelector('button.delete').addEventListener('click', function(e) {
			e.stopPropagation();
			controller.remove(index);
		});

		// Make title scroll if it overflow
		ScrollingTextLib.add(elm.querySelector('.description .title span'));
		return elm;
	}
};

PersonalsPlaylistView.prototype = new View();

PersonalsPlaylistView.prototype._bindEvents = function() {
	this.$closeButton.addEventListener('click', function() {
		viewManager.hideView('personalsPlaylist');
		this.clearList();
	}.bind(this));

	this.$createPlaylistButton.addEventListener('click', function() {
		this.controller.createNewPlaylist('New playlist');
	}.bind(this));
};

var PersonalPlaylist = function PersonalPlaylist(app) {
	this.app = app;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));


	app.on('initialized', function() {
		this.view = new PersonalsPlaylistView(this);
	}.bind(this));

	app.on('openPersonalsPlaylist', function() {
		this.getPlaylistPersonal();
		viewManager.displayView('personalsPlaylist');
	}.bind(this));
};

PersonalPlaylist.moduleName = PersonalPlaylist.prototype.moduleName = 'personalsPlaylist';

TiPlayerApp.modules.push(PersonalPlaylist);

PersonalPlaylist.prototype.listenSocket = function(socket) {
	/* Unused code for the moment */
	socket.on('playlist/createPersonal/success', function(playlistId) {
		Logger.debug('playlist created %s', playlistId);
		this.view.addPlaylistToList(playlistId);
	}.bind(this));
	socket.on('playlist/createPersonal/error', function(err) {
		Logger.error('playlist creation error %s', err);
	});
};

PersonalPlaylist.prototype.createNewPlaylist = function(playlistName) {
	this.app.socket.emit('playlist/createPersonal', {playlistName: playlistName});
};

PersonalPlaylist.prototype.deletePersonalPlaylist = function(playlistId) {
	this.app.socket.emitWithResponse('playlist/deletePersonal', {playlistId: playlistId}, function(err, _response) {
		if (err) {
			Logger.error('playlist deletion error %s', err);
			return;
		}
		Logger.debug('playlist deleted %s', playlistId);
		this.view.removePlaylistSection(playlistId);
	}.bind(this));
};

PersonalPlaylist.prototype.changePlaylistTitle = function(playlistId, title) {
	this.app.socket.emit('playlist/setTitle', {playlistId: playlistId, title: title});
};

PersonalPlaylist.prototype.getPlaylistPersonal = function(_playlistId, _title) {
	this.app.socket.emitWithResponse('playlist/getPersonal', null, function(err, playlistInfo) {
		if (err) {
			Logger.error('playlist/getPersonal/error', err);
			return;
		}
		this.view.clearList();
		for (var i = 0 ; i < playlistInfo.length ; i++) {
			this.view.addPlaylistToList(playlistInfo[i].playlistId);
		}
	}.bind(this));
};


var PlaylistAdderToolTip = function PlaylistAdderToolTip(app) {
	this.app = app;


	this.toolTipBox = document.querySelector('#tooltip_playlist_adder');
	this.list = this.toolTipBox.querySelector('.playlist_selector_list');
	this.playlistCreationElement = this.list.querySelector('.create_new_playlist');
	this.playlistChoiceTemplate = Handlebars.compile(document.querySelector('#select-adding-playlist-template').innerHTML);

	this.relatedVideo = null;

	this.onSelectedCallback = null;


	this.documentClickCallback = function(e) {
		var target = e.target;
		while (target !== null) {
			if (target == this.toolTipBox) {
				return;
			}
			target = target.parentElement;
		}
		this.hide();
	}.bind(this);

	this.playlistCreationElement.querySelector('button[type="submit"]').addEventListener('click', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var name = this.playlistCreationElement.querySelector('input[type="text"]').value;

		if (name.trim() === '') return;

		var socket = this.app.socket;

		var that = this;

		socket.emitWithResponse('playlist/createPersonal', {playlistName: name}, function(err, playlistId) {
			if (err) {
				alert('Erreur : ' + err);
				return;
			}
			that.playlistSelected(playlistId);
		});
	}.bind(this));
};

PlaylistAdderToolTip.moduleName = PlaylistAdderToolTip.prototype.moduleName = 'playlistAdder';

TiPlayerApp.modules.push(PlaylistAdderToolTip);

PlaylistAdderToolTip.prototype.getPlaylistChoice = function(callback) {

	this.setLoading(true);
	this.show();
	this.getUsersPlaylist(function(err, playlistsInfo) {
		if (err) {
			alert(err);
			this.hide();
			return;
		}
		this.setPlaylists(playlistsInfo);
		this.setLoading(false);

		this.onSelectedCallback = function(playlistId) {
			this.onSelectedCallback = null;

			// send playlist to the callback
			callback(playlistId);

			this.hide();
		}.bind(this);
	}.bind(this));
};

PlaylistAdderToolTip.prototype.addVideoToTiPlaylist = function(videoID) {
	this.getPlaylistChoice(function(playlistId) {
		this._addVideoToPlaylist(videoID, 'youtube', playlistId);
	}.bind(this));
};


PlaylistAdderToolTip.prototype.addPlaylistToTiPlaylist = function(toAddPlaylistId) {
	this.getPlaylistChoice(function(playlistId) {
		this._addPlaylistToPlaylist(toAddPlaylistId, 'youtube', playlistId);
	}.bind(this));
};

PlaylistAdderToolTip.prototype.getUsersPlaylist = function(callback) {
	this.app.socket.emitWithResponse('playlist/getAvailable', null, callback);
};

PlaylistAdderToolTip.prototype.show = function() {
	this.toolTipBox.classList.remove('hidden');
	document.addEventListener('click', this.documentClickCallback);

};

PlaylistAdderToolTip.prototype.hide = function() {
	this.toolTipBox.classList.add('hidden');
	document.removeEventListener('click', this.documentClickCallback);
};
PlaylistAdderToolTip.prototype.setLoading = function(_bool) {
	// TODO: add loading visualisation
	Logger.debug('TODO: PlaylistAdderToolTip.prototype.setLoading');
};

PlaylistAdderToolTip.prototype.setPlaylists = function(playlistsInfo) {
	// remove old choices
	var toRemove = this.list.querySelectorAll('.existing_playlist');
	var i;
	for (i = 0 ; i < toRemove.length ; i++) {
		toRemove[i].remove();
	}

	// create new choices
	for (i = playlistsInfo.length - 1 ; i >= 0 ; i--) {
		var playlist = playlistsInfo[i];
		var html = this.playlistChoiceTemplate({playlistName: playlist.title, playlistId: playlist.playlistId});
		this.list.insertAdjacentHTML('afterbegin', html);
		var elm = this.list.firstChild;

		bindEvents(elm);
	}

	var that = this;

	function bindEvents(elm) {
		elm.addEventListener('click', function(_event) {
			var playlistId = elm.dataset.pid;
			that.playlistSelected(playlistId);
		});
	}

	// Clear last entry on the create playlist choice
	var input = this.playlistCreationElement.querySelector('input[type="text"]');
	input.value = '';
};

PlaylistAdderToolTip.prototype.playlistSelected = function(playlistId) {
	if (this.onSelectedCallback !== null) {
		this.onSelectedCallback(playlistId);
	}
};

PlaylistAdderToolTip.prototype._addVideoToPlaylist = function(videoID, origin, playlistId) {
	var socket = this.app.socket;
	socket.emit('playlist/addVideo', {playlistId: playlistId, videoID: videoID, origin: origin});
};

PlaylistAdderToolTip.prototype._addPlaylistToPlaylist = function(toAddPlaylistId, origin, playlistId) {
	this.app.socket.emitWithResponse('playlist/addPlaylist', {playlistId: playlistId, YTPlaylistId: toAddPlaylistId, origin: origin}, function(err, _response) {
		if (err) {
			alert(err || 'Vous n\'etes pas autorisé à réaliser cette action');
			return;
		}

	});
};

var VideoToolTip = function VideoToolTip(app) {
	this.app = app;
  
	this.toolTipBox = document.querySelector('#tooltip_video');
	this.toolTipBoxLoader = this.toolTipBox.querySelector('.loading');
	this.toolTipBoxCheckboxAction = this.toolTipBox.querySelector('#tooltipActionCheckBox');

	var that = this;
	this.toolTipBox.querySelector('.play_action').addEventListener('click', function() {
		that.addVideo(that.toolTipBox.dataset.videoID);
		hideToolTip();
	});

	this.toolTipBox.querySelector('.add_to_playlist_action').addEventListener('click', function() {
		that.addToPlaylist(that.toolTipBox.dataset.videoID);
		hideToolTip();
	});

	this.toolTipBox.querySelector('.see_on_yt_action').addEventListener('click', function() {
		var url = 'https://www.youtube.com/watch/' + that.toolTipBox.dataset.videoID;
		var win = window.open(url, '_blank');
		win.focus();
		hideToolTip();
	});

	this.toolTipBox.addEventListener('click', function(e) {
		e.stopPropagation();
	});

	var hideToolTip = function() {
		document.removeEventListener('click', hideToolTip);
		that.toolTipBox.classList.add('hidden');
		that.toolTipBoxCheckboxAction.checked = false;
		that.toolTipBoxLoader.classList.remove('hidden');
	};

	document.addEventListener('click', function(e) {
		if (e.target && e.target.classList.contains('video_link')) {
			e.preventDefault();
			e.stopPropagation();
			that.toolTipBox.dataset.videoID = e.target.dataset.id;

			that.toolTipBox.classList.remove('hidden');

			var toolBoxWidth = that.toolTipBox.clientWidth || 250;

			var windowWidth = document.documentElement.scrollWidth;

			// The styles makes the point top/left the center bottom of the element
			if (e.pageX + toolBoxWidth / 2 >= windowWidth) {
				that.toolTipBox.style.left = (windowWidth - toolBoxWidth / 2) + 'px';
			} else if (e.pageX - toolBoxWidth / 2 < 0) {
				that.toolTipBox.style.left = toolBoxWidth / 2 + 'px';
			} else {
				that.toolTipBox.style.left = e.pageX + 'px';
			}


			that.toolTipBox.style.top = e.pageY + 'px';
			that.toolTipBoxCheckboxAction.checked = true;
      

			document.addEventListener('click', hideToolTip);

			YTData.getVideoData(e.target.dataset.id, function(err, video) {
				if (err) {
					hideToolTip();
					return alert(err);
				}
				that.toolTipBoxLoader.classList.add('hidden');
				that.toolTipBox.querySelector('.video_elm img').src = video.thumbnail;
				that.toolTipBox.querySelector('.title').innerHTML = video.title;
				that.toolTipBox.querySelector('.channel').innerHTML = video.channelTitle;
				that.toolTipBox.querySelector('.video_duration').innerHTML = Utils.stringifyTime(video.duration);
			});
		}
	});
};

VideoToolTip.moduleName = VideoToolTip.prototype.moduleName = 'videoToolTip';

TiPlayerApp.modules.push(VideoToolTip);
VideoToolTip.prototype.addVideo = function(videoID) {
	this.app.socket.emit('player/setVideo', {videoID: videoID, origin: 'youtube'});
};

VideoToolTip.prototype.addToPlaylist = function(videoID) {
	this.app.playlistAdder.addVideoToTiPlaylist(videoID);
};


var WidgetInfoView = function WidgetInfoView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#widget_div');
	this.closeButton = this.element.querySelector('.close_section_btn');
	this.widgetButton = document.querySelector('#widget_link');
	this.widgetLink = document.querySelector('#widget_url_toolbar');

	this.widgetLink.href = 'javascript:(function(){window.open(\'' + window.location.origin + '/widget/widget.html?url=\' + window.location.href,\'\',\'height=250,width=300\', false);}());';

	viewManager.registerView('widgetInfo', this, '/widgetInfo');

	this._bindEvents();
};

WidgetInfoView.prototype = new View();

WidgetInfoView.prototype._bindEvents = function() {
	this.widgetLink.addEventListener('click', function(e) {
		e.preventDefault();
		e.stopPropagation();
	});

	this.closeButton.addEventListener('click', function() {
		viewManager.hideView('widgetInfo');
	});

	this.widgetButton.addEventListener('click', function(e) {
		e.stopPropagation();
		viewManager.displayView('widgetInfo');
	});
};


window.addEventListener('load', function() {
	var widgetInfoView = new WidgetInfoView(null);
});

var RoomView = function RoomView() {
	this.element = document.querySelector('#room');

	viewManager.registerView('room', this, null);
};

RoomView.prototype = new View();

var Room = function Room(app) {
	this.app = app;

	this.id = null;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new RoomView();
		this.player = this.app.player;
		this.chat = this.app.chat;
		this.playlist = this.app.playlist;
		this.userlist = this.app.userlist;
		this.bot = this.app.bot;
		this.history = this.app.history;
		this.search = this.app.search;

		this.roomInfoView = new RoomInfoView(this);
	}.bind(this));

	app.on('socket/reconnect', function() {
		if (this.id !== null) {
			this.app.socket.emit('room/join', {id: this.id});
		}
	}.bind(this));

};

Room.moduleName = Room.prototype.moduleName = 'room';

TiPlayerApp.modules.push(Room);

Room.prototype.listenSocket = function(socket) {
	var that = this;
	Logger.debug('Room listen socket');
	socket.on('roomData', function(data) {
		Logger.debug('room data', data);
		that.dataUpdate(data);
	});

	socket.on('roomJoin', function(data) {
		Logger.debug('room join data', data);
		// TODO: choose best event name
		that.app.emit('roomJoined', data);
		that.app.emit('room/join', data);

		viewManager.displayView('room');
		that.dataUpdate(data);
	});


	socket.on('roomLeave', function(roomId) {
		that.app.emit('roomLeaved');
		viewManager.hideView('room');

		that.chat.clearMessages();
		Logger.debug('room leave', roomId);
	});

	socket.on('roomClosed', function(data) {
		Logger.debug('room closed', data);
		viewManager.hideView('room');
		that.app.emit('openRoomSelector');
	});

	socket.on('userJoin', function(userInfo) {
		Logger.debug('user join', userInfo);
	});

	socket.on('userLeave', function(userInfo) {
		Logger.debug('user leave', userInfo);
	});
};

Room.prototype.dataUpdate = function(data) {
	if (data.player) {
		this.player.dataUpdate(data.player);
	}
	if (data.chat) {
		this.chat.dataUpdate(data.chat);
	}
	if (data.playlist) {
		this.playlist.dataUpdate(data.playlist);
	}
	if (data.history) {
		this.history.dataUpdate(data.history);
	}
	if (data.bot) {
		this.bot.dataUpdate(data.bot);
	}
	if (data.userlist) {
		Logger.debug('userlist update', data.userlist);
		this.userlist.setData(data.userlist);
	}
	if (data.name) {
		Logger.debug('name update : ', data.name);
		this.roomInfoView.setName(data.name);
		this.app.emit('roomNameChanged', data.name);
	}
	if (data.creator) {
		Logger.debug('creator update : ', data.creator);
		if (TiPlayerApp.account.userData.id == data.creator.id ||
       TiPlayerApp.account.userData.grade >= 3) {
			this.roomInfoView.displayRoomSettings();
		} else {
			this.roomInfoView.hideRoomSettings();
		}
	}
	if (data.id) {
		this.id = data.id;
	}
};

Room.prototype.roomConfigButtonClick = function() {
	this.app.emit('openRoomConfig');
};

var Bot = function Bot(app) {
	this.app = app;

	this.botEnabled = false;
	this.botButton = document.querySelector('#plugbot');

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	this.listenViewEvents();
};

Bot.moduleName = Bot.prototype.moduleName = 'bot';

TiPlayerApp.modules.push(Bot);

Bot.prototype.listenSocket = function(socket) {
	socket.on('bot/update', function(data) {
		Logger.debug('Bot update data recieved', data);
		this.dataUpdate(data);
	}.bind(this));
};

Bot.prototype.enable = function() {
	this.app.socket.emit('bot/enable');
};
Bot.prototype.disable = function() {
	this.app.socket.emit('bot/disable');
};
Bot.prototype.toggleState = function() {
	if (this.botEnabled) {
		this.disable();
	} else {
		this.enable();
	}
};

Bot.prototype.dataUpdate = function(data) {
	this.botButton.querySelectorAll('i').forEach(function(elm) { elm.classList.remove('selected'); });

	this.botEnabled = data.active;
	if (data.active) {
		this.botButton.children[1].classList.add('selected');
	} else {
		this.botButton.children[0].classList.add('selected');
	}
};

Bot.prototype.listenViewEvents = function() {
	this.botButton.addEventListener('click', this.toggleState.bind(this));
};

var ChatView = function ChatView(controller) {
	this.controller = controller;

	this.element = document.querySelector('#chatSection');


	this.messageUserTemplate = Handlebars.compile(this.element.querySelector('#message-user-template').innerHTML);
	this.messageInfoTemplate = Handlebars.compile(this.element.querySelector('#message-info-template').innerHTML);
	this.messageErrorTemplate = Handlebars.compile(this.element.querySelector('#message-error-template').innerHTML);


	this.$messagesList = this.element.querySelector('#messagesList');
	this.$chatInput = this.element.querySelector('#inputChat');
	this.$toggleChatInfosButton = this.element.querySelector('#toggleChatInfos');
	this.$toggleChatInfosButtonItem = this.$toggleChatInfosButton.querySelector('i');

	this._bindEvents();
};

ChatView.prototype = new View();

ChatView.prototype._bindEvents = function() {
	this.$chatInput.addEventListener('keypress', function(event) {
		var message = this.$chatInput.value.trim();
		if (event.keyCode == 13 && message !== ''){
			if (!this.isCommand(message)){
				this.controller.message(message);
			} else {
				var args = (message.slice(1)).split(' ');
				var commandName = args.splice(0, 1)[0];
				this.controller.command({
					commandName: commandName,
					arguments: args
				});
			}
			this.$chatInput.value = '';
		}
	}.bind(this));

	this.$toggleChatInfosButton.addEventListener('click', function() {
		this.controller.toggleChatInfos();
	}.bind(this));

	document.addEventListener('click', function(e) {
		if (e.target && e.target.classList.contains('pseudo_link')) {
			e.preventDefault();
			e.stopPropagation();
			this.$chatInput.value += '@' + e.target.innerHTML + ' ';
			this.$chatInput.focus();
		}
	}.bind(this));
};

ChatView.prototype.isCommand = function(message) {
	return message.length !== 0 && message[0] == '/';
};

ChatView.prototype.scrollToBottom = function() {
	this.$messagesList.scrollTop = this.$messagesList.scrollHeight - this.$messagesList.clientHeight;
};

ChatView.prototype.addBBCode = function(html) {
	html = html.replace(/@([a-zA-Z0-9]+) ?/g, '<a href="#" class="pseudo_link">$1</a> ');
	html = html.replace(/\[youtube=(.+?)\](.+?)\[\/youtube\]/igm, '<a href="#" data-id="$1" class="video_link">$2</a> ');
	html = html.replace(/\[youtube&#x3D;(.+?)\](.+?)\[\/youtube\]/igm, '<a href="#" data-id="$1" class="video_link">$2</a> ');
	html = html.replace(/(https?:\/\/)([a-zA-Z0-9./$&#_\-?=]+) ?/igm, '<a href="$1$2" target="_blank">$2</a> '); // set target to open in new tab
	return html;
};

ChatView.prototype.setInfoVisibility = function(infoMessagesVisible) {
	this.$toggleChatInfosButtonItem.innerHTML = infoMessagesVisible ? 'visibility' : 'visibility_off';
};

ChatView.prototype.addMessage = function(messageData) {
	var html = '';

	var date = this._formatDate(messageData.date);
	switch (messageData.type){
	case 'user':
		// TODO: filter the username
		var pseudo = messageData.user.visibleName; /*(data.pseudo == this.userPseudo)? 'vous':*/
		html = this.messageUserTemplate({
			visibleName: pseudo,
			username: messageData.user.username,
			message: messageData.message,
			avatar_src: messageData.user.profilePicture,
			date: date
		});
		this.$messagesList.insertAdjacentHTML('beforeend', html);
		break;
	case 'info':
		html = this.messageInfoTemplate({message: messageData.message, date: date});
		this.$messagesList.insertAdjacentHTML('beforeend', html);
		break;
	case 'error':
		html = this.messageErrorTemplate({message: messageData.message, date: date});
		this.$messagesList.insertAdjacentHTML('beforeend', html);
		break;
	default:
		Logger.warn('unknown message type', messageData);
		break;
	}

	// Add BBcode to message
	var elm = this.$messagesList.lastChild.querySelector('p');
	elm.innerHTML = this.addBBCode(elm.innerHTML);

	this.scrollToBottom();
};

ChatView.prototype.clearMessages = function() {
	var messages = this.$messagesList.querySelectorAll('li');
	for (var i = 0 ; i < messages.length ; i++) {
		messages[i].remove();
	}
};

ChatView.prototype._formatDate = function(stringDate) {
	var date = new Date(stringDate);
	var msInterval = Date.now() - date.getTime();
	if (msInterval > 24 * 60 * 60 * 1000) {
		return this._formatDayDate(date) + ' ' + this._formatTimeDate(date);
	} else {
		return this._formatTimeDate(date);
	}
};

ChatView.prototype._formatDayDate = function(date) {
	var day = '' + date.getDate();
	var month = '' + (date.getMonth() + 1);
	var year = '' + date.getFullYear();
	while (day.length < 2) { day = '0' + day; }
	while (month.length < 2) { month = '0' + month; }
	return day + '/' + month + '/' + year;
};

ChatView.prototype._formatTimeDate = function(date) {
	var hours = '' + date.getHours();
	var minutes = '' + date.getMinutes();
	var seconds = '' + date.getSeconds();
	while (hours.length < 2) { hours = '0' + hours; }
	while (minutes.length < 2) { minutes = '0' + minutes; }
	while (seconds.length < 2) { seconds = '0' + seconds; }
	return hours + ':' + minutes + ':' + seconds;
};

var Chat = function Chat(app) {
	this.app = app;

	this.messages = [];
	this.showInfos = true;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new ChatView(this);
	}.bind(this));
};

Chat.moduleName = Chat.prototype.moduleName = 'chat';

TiPlayerApp.modules.push(Chat);

Chat.prototype.listenSocket = function(socket) {
	var that = this;
	socket.on('chat/message', function(data) {
		that._handleNewMessage(data);
	});
	socket.on('chat/mod', function(data) {
		Logger.debug('chat/mod data recieved', data);
		// TODO: To implement when the feature is implemented
	});
	socket.on('errorMessage', function(err) {
		Logger.error('errMessage recieved : ', err);
	});
};

Chat.prototype.toggleChatInfos = function() {
	this.showInfos = !this.showInfos;
	this.view.setInfoVisibility(this.showInfos);
	this._refreshAllMessages();
};

Chat.prototype.message = function(message) {
	this.app.socket.emit('chat/sendMessage', message);
};
Chat.prototype.moderate = function(_data) {
	// Implementation not done on the server
	Logger.warn('chat.moderate called but not implemented');
	// this.app.socket.emit('chat/moderate', data);
};
Chat.prototype.command = function(data) {
	this.app.socket.emit('chat/sendCommand', data);
};

Chat.prototype.dataUpdate = function(data) {
	this.messages = data.sort(function(message1, message2) {
		return message1.date < message2.date ? -1 : 1;
	}).slice(-5);
	this._refreshAllMessages();
};

Chat.prototype.clearMessages = function() {
	this.messages = [];
	this.view.clearMessages();
};


Chat.prototype._handleNewMessage = function(message) {
	this.messages.push(message);
	if (this._shouldShowMessage(message)) {
		this.view.addMessage(message);
	}
};

Chat.prototype._refreshAllMessages = function() {
	this.view.clearMessages();
	for (var i = 0; i < this.messages.length; i++) {
		var message = this.messages[i];
		if (this._shouldShowMessage(message)) {
			this.view.addMessage(message);
		}
	}
};

Chat.prototype._shouldShowMessage = function(message) {
	return message.type != 'info' || this.showInfos;
};

var RoomConfigView = function RoomConfigView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#roomSettingsSection');

	this.default = {
		name:'',
		authMap: {}
	};

	this.$configForm = this.element.querySelector('form');
	this.$closeButton = this.element.querySelector('.close_section_btn');
	this.$roomCloseButton = this.element.querySelector('#roomDeleteButton');
	this.$loadingDiv = this.element.querySelector('#configLoadingDiv');

	this._bindEvents();

	viewManager.registerView('roomConfig', this, '/roomConfig');
};

RoomConfigView.prototype = new View();


RoomConfigView.prototype._bindEvents = function() {
	var that = this;

	this.$configForm.addEventListener('submit', function(e) {
		e.stopPropagation();
		e.preventDefault();

		// get data and call controller function
		var elements = that.$configForm.elements;

		var roomConfigData = {
			name: elements.name.value,
			permissions: {
				playerEdit: elements.playerEdit.value,
				playerVideo: elements.playerVideo.value,
				playlistState: elements.playlistState.value,
				playlistEdit: elements.playlistEdit.value,
				chatMessage: elements.chatMessage.value,
				botState: elements.botState.value
			}
		};

		that.controller.configureRoom(roomConfigData);
	});

	this.$configForm.addEventListener('reset', function(e) {
		e.stopPropagation();
		e.preventDefault();

		that.updateData(that.default);
	});

	this.$closeButton.addEventListener('click', function() {
		viewManager.hideView('roomConfig');
	});

	this.$roomCloseButton.addEventListener('click', function() {
		var roomData = that.controller.roomData;
		if (confirm('Voulez-vous vraiement fermer la salle ' + roomData.name + '(' + roomData.id + ')' + '\nCette action est définitive.')) {
			that.controller.closeRoom(roomData.id);
		}
	});
};

RoomConfigView.prototype.updateData = function(roomData) {
	var elements = this.$configForm.elements;

	if (roomData.name) {
		elements.name.value = roomData.name;
		this.default.name = roomData.name;
	}

	if (roomData.authMap) {
		elements.playerEdit.value = roomData.authMap.playerEdit;
		elements.playerVideo.value = roomData.authMap.playerVideo;
		elements.playlistState.value = roomData.authMap.playlistState;
		elements.playlistEdit.value = roomData.authMap.playlistEdit;
		elements.chatMessage.value = roomData.authMap.chatMessage;
		elements.botState.value = roomData.authMap.botState;
		this.default.authMap = roomData.authMap;
	}
};

RoomConfigView.prototype.showLoading = function() {
	this.$loadingDiv.classList.remove('hidden');
};

RoomConfigView.prototype.hideLoading = function() {
	this.$loadingDiv.classList.add('hidden');
};

var RoomConfig = function RoomConfig(app) {
	this.app = app;
	this.roomData = {};

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new RoomConfigView(this);
	}.bind(this));

	app.on('openRoomConfig', function() {
		viewManager.displayView('roomConfig');
	}.bind(this));
};

RoomConfig.moduleName = RoomConfig.prototype.moduleName = 'roomConfig';

TiPlayerApp.modules.push(RoomConfig);

RoomConfig.prototype.listenSocket = function(socket) {
	var that = this;
	socket.on('RoomConfig/update', function(data) {
		Logger.debug('RoomConfig update data recieved', data);
	});
	socket.on('roomData', function(data) {
		that.dataUpdate(data);
	});

	socket.on('roomJoin', function(data) {
		that.dataUpdate(data);
	});
};

RoomConfig.prototype.configureRoom = function(configRoomData) {
	var that = this;
	this.app.socket.emitWithResponse('room/configure', configRoomData, function(err, _response) {
		if (err) {
			Logger.error('room fail when configure', err);
			that.view.hideLoading();
			return;
		}
		that.view.hideLoading();
		viewManager.hideView('roomConfig');
	});
  
	this.view.showLoading();
};

RoomConfig.prototype.closeRoom = function(roomId) {
	var that = this;
	this.app.socket.emitWithResponse('rooms/close', {roomId: roomId}, function(err, _response) {
		if (err) {
			Logger.error('room fail when close', err);
			that.view.hideLoading();
			return;
		}
		that.view.hideLoading();
		viewManager.hideView('roomConfig');
	});

	this.view.showLoading();
};

RoomConfig.prototype.dataUpdate = function(roomData) {
	this.roomData = roomData;
	this.view.updateData(roomData);
};

var RoomCreationView = function RoomCreationView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#roomCreationSection');

	this.$closeButton = this.element.querySelector('.close_section_btn');

	this.$creationForm = this.element.querySelector('form');
	this.$nameInput = this.$creationForm.querySelector('input[name="name"]');
	this.$errorMessage = this.$creationForm.querySelector('.form_error');

	this._bindEvents();

	viewManager.registerView('roomCreation', this, '/roomCreate');
};

RoomCreationView.prototype = new View();


RoomCreationView.prototype._bindEvents = function() {
	var that = this;

	this.$creationForm.addEventListener('submit', function(e) {
		e.stopPropagation();
		e.preventDefault();

		var roomName = that.$nameInput.value;

		that.controller.createRoom(roomName);
	});

	this.$creationForm.addEventListener('reset', closeRoomCreation);

	this.$closeButton.addEventListener('click', closeRoomCreation);

	function closeRoomCreation(e) {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		viewManager.hideView('roomCreation');
	}
};

RoomCreationView.prototype.setErrorMessage = function(message) {
	if (message) {
		this.$errorMessage.classList.remove('hidden');
	} else {
		this.$errorMessage.classList.add('hidden');
	}
	this.$errorMessage.innerHTML = message;
};


var RoomCreation = function RoomCreation(app) {
	this.app = app;
	this.roomData = {};

	app.on('initialized', function() {
		this.view = new RoomCreationView(this);
	}.bind(this));

	app.on('openRoomCreation', function() {
		viewManager.displayView('roomCreation');
	}.bind(this));
};

RoomCreation.moduleName = RoomCreation.prototype.moduleName = 'roomCreation';

TiPlayerApp.modules.push(RoomCreation);

RoomCreation.prototype.createRoom = function(roomName) {
	this.app.socket.emitWithResponse('rooms/create', {name: roomName}, function(err, _response) {
		if (err) {
			this.view.setErrorMessage(err || 'impossible de créer une nouvelle salle');
			return;
		}
		this.view.setErrorMessage('');
		viewManager.hideView('roomCreation');
	}.bind(this));
};

var PlayerHistoryView = function PlayerHistoryView(controller) {
	this.controller = controller;
	this.historyElements = {};

	this.$historySection = document.querySelector('#playerHistory');

	this.$toggleHistoryButton = document.querySelector('#historyToggle');
	this.$historyContent = this.$historySection.querySelector('.history_content');
	this.$dismissOverlay = this.$historySection.querySelector('.dismiss_overlay');

	this.historyEntryTemplate = Handlebars.compile(document.querySelector('#history-content-row-template').innerHTML);

	this._bindEvents();
};

PlayerHistoryView.prototype = new View();

PlayerHistoryView.prototype._bindEvents = function() {
	var that = this;
	this.$toggleHistoryButton.addEventListener('click', function() {
		that.$historyContent.classList.toggle('show');
		that.$dismissOverlay.classList.toggle('show');
	});
	this.$dismissOverlay.addEventListener('click', function() {
		that.$historyContent.classList.remove('show');
		that.$dismissOverlay.classList.remove('show');
	});
};

PlayerHistoryView.prototype.setHistoryEntries = function(historyEntries) {
	this.clearEntries();
	for (var i = historyEntries.length - 1; i >= 0; i--) {
		this.addEntry(historyEntries[i]);
	}
};

PlayerHistoryView.prototype.clearEntries = function() {
	for (var entryID in this.historyElements) {
		this.historyElements[entryID].remove();
	}
	this.historyElements = [];
};

PlayerHistoryView.prototype.addEntry = function(historyEntry) {

	var html = this.historyEntryTemplate({
		thumbnailsrc: 'https://i3.ytimg.com/vi/' + historyEntry.video.videoID + '/hqdefault.jpg',
		time: Utils.stringifyTime(historyEntry.video.duration),
		title: historyEntry.video.title,
		channel: historyEntry.video.channelTitle,
		date: this._formatStringDate(historyEntry.date),
		visibleUserName: historyEntry.visibleName
	});
	this.$historyContent.insertAdjacentHTML('afterbegin', html);
	var entryElement = this.$historyContent.firstChild;
	this.historyElements[historyEntry.historyID] = entryElement;

	var that = this;
	ScrollingTextLib.add(entryElement.querySelector('.description .title span'));
	entryElement.querySelector('.add_to_playlist_action').addEventListener('click', function(e) {
		e.stopPropagation();
		that.controller.addVideoToPlaylist(historyEntry.video.videoID);
	});
	entryElement.querySelector('.play_action').addEventListener('click', function() {
		that.controller.playVideo(historyEntry.video.videoID);
	});

	entryElement.querySelector('.see_on_yt_action').addEventListener('click', function() {
		var url = 'https://www.youtube.com/watch/' + historyEntry.video.videoID;
		var win = window.open(url, '_blank');
		win.focus();
	});
};

PlayerHistoryView.prototype.removeEntry = function(historyEntryID) {
	var element = this.historyEntries[historyEntryID];
	if (element === undefined) { return; }
	element.remove();
	delete this.historyEntries[historyEntryID];
};

PlayerHistoryView.prototype._formatStringDate = function(stringDate) {
	var date = new Date(stringDate);
	var options = {weekday: 'short', year: '2-digit', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
	return date.toLocaleDateString(undefined, options);
};

var History = function History(app) {
	this.app = app;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new PlayerHistoryView(this);
	}.bind(this));
};

History.moduleName = History.prototype.moduleName = 'history';

TiPlayerApp.modules.push(History);

History.prototype.listenSocket = function(socket) {
	var that = this;
	socket.on('history/add', function(historyEntry) {
		that.view.addEntry(historyEntry);
	});
	socket.on('history/remove', function(data) {
		that.view.removeEntry(data.hid);
	});
};

History.prototype.get = function() {
	this.app.socket.emit('history/getHistory');
};
History.prototype.moderate = function(_data) {
	// Implementation not done on the server
	Logger.warn('history.moderate called but not implemented');
	// this.app.socket.emit('history/moderate', data);
};

History.prototype.dataUpdate = function(historyEntries) {
	this.view.setHistoryEntries(historyEntries);
};

History.prototype.addVideoToPlaylist = function(videoID) {
	this.app.playlistAdder.addVideoToTiPlaylist(videoID);
};

History.prototype.playVideo = function(videoID) {
	this.app.socket.emit('player/setVideo', {videoID: videoID, origin: 'youtube'});
};

var RoomInfoView = function RoomInfoView(controller) {
	this.controller = controller;

	this.$roomName = document.querySelector('#roomName');
	this.$roomConfigButtom = document.querySelector('#configRoomButton');

	this.$roomConfigButtom.addEventListener('click', function() {
		this.controller.roomConfigButtonClick();
	}.bind(this));
};

RoomInfoView.prototype = new View();

RoomInfoView.prototype.setName = function(roomName) {
	this.$roomName.innerHTML = roomName;
};

RoomInfoView.prototype.displayRoomSettings = function() {
	this.$roomConfigButtom.classList.remove('hidden');
};

RoomInfoView.prototype.hideRoomSettings = function() {
	this.$roomConfigButtom.classList.add('hidden');
};

var PlayerView = function PlayerView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#roomPlayer');

	this.$videoTitle = this.element.querySelector('#video_title');
	this.$time = this.element.querySelector('#video_progress_label');
	this.$stateButton = this.element.querySelector('#state_button');
	this.$volumeButton = this.element.querySelector('#volume_level_button');
	this.$volumeRange = this.element.querySelector('#volume_range');
	this.$fullscreenButton = this.element.querySelector('#fullscreen_button');
	this.$skipButton = this.element.querySelector('#skip_button');
	this.$actionsContainerElement = this.element.querySelector('.actions_enabler');
	this.$debugNativeControlsButton = this.element.querySelector('.player_header .actions .action_debug_native_controls');


	this.element.querySelector('.player_header .actions .action_playlist_add')
		.addEventListener('click', this.controller.actionAddVideoToPlaylist.bind(this.controller));
	this.element.querySelector('.player_header .actions .action_youtube')
		.addEventListener('click', this.controller.actionOpenYT.bind(this.controller));
	this.element.querySelector('.player_header .actions .action_search_recommandation')
		.addEventListener('click', this.controller.actionSearchRelated.bind(this.controller));
	this.$debugNativeControlsButton.addEventListener('click', this.controller.toggleNativeControls.bind(this.controller));

	this.$debugNativeControlsButton.style.display = 'none';

	this.progressBar = new ProgressBar('video_progress_bar');

	this.fullscreen = false;

	this.durationTime = 0;
	this.isChangingProgression = false;
	this.timeSave = 0;

	this._bindEvents();
};

PlayerView.prototype = new View();

PlayerView.prototype._bindEvents = function() {
	this.$stateButton.addEventListener('click', function(_event){
		this.controller.askChangeState();
	}.bind(this));

	this.$volumeButton.addEventListener('click', function(_event){
		this.controller.switchMute();
	}.bind(this));

	this.$volumeRange.addEventListener('input', function(e){
		this.controller.setVolume(parseInt(e.target.value, 10));
	}.bind(this));


	this.$fullscreenButton.addEventListener('click', function(){
		this.toggleFullScreen();
	}.bind(this));

	this.keyPressHandler = function(event) {
		this.$debugNativeControlsButton.style.display = event.shiftKey ? 'block' : 'none';
	}.bind(this);

	this.$actionsContainerElement.addEventListener('mouseenter', function(event){
		this.keyPressHandler(event);
		window.addEventListener('keydown', this.keyPressHandler);
		window.addEventListener('keyup', this.keyPressHandler);
	}.bind(this));

	this.$actionsContainerElement.addEventListener('mouseleave', function(event){
		this.keyPressHandler(event);
		window.removeEventListener('keydown', this.keyPressHandler);
		window.removeEventListener('keyup', this.keyPressHandler);
	}.bind(this));

	// listen when fullscreen is disabled by escape key
	var FShandler = function(){
		this.updateFullScreenState();
	}.bind(this);
	document.addEventListener('fullscreenchange', FShandler);
	document.addEventListener('webkitfullscreenchange', FShandler);
	document.addEventListener('mozfullscreenchange', FShandler);
	document.addEventListener('MSFullscreenChange', FShandler);

	this.progressBar.on('update', function(newTime) {
		this.controller.changeProgress(newTime);
	}.bind(this));


	this.$skipButton.addEventListener('click', this.controller.skipVideo.bind(this.controller));

	if ('mediaSession' in navigator) {
		navigator.mediaSession.setActionHandler('play', this.controller.askChangeState.bind(this.controller));
		navigator.mediaSession.setActionHandler('pause', this.controller.askChangeState.bind(this.controller));
		navigator.mediaSession.setActionHandler('nexttrack', this.controller.skipVideo.bind(this.controller));
	}
};

PlayerView.prototype.setVideoTitle = function(videoTitle, channelTitle) {
	var title = videoTitle;
	if (channelTitle) {
		title += ' - ' + channelTitle;
	}
	this.$videoTitle.innerHTML = title;
};

PlayerView.prototype.updateProgression = function(currentTime, durationTime, force) {
	if (currentTime === undefined || durationTime === undefined) {
		return;
	}
	if (this.isChangingProgression && !force) {
		this.timeSave = currentTime;
		return;
	}


	this.progressBar.setDuration(durationTime);
	this.progressBar.setCurrentTime(currentTime);
	this.durationTime = durationTime;
	var text = Utils.stringifyTime(currentTime) + ' / ' + Utils.stringifyTime(durationTime);
	this.$time.innerHTML = text;

};

PlayerView.prototype.setStateDisplay = function(state) {
	this.$stateButton.querySelectorAll('i').forEach(function(elm) {elm.classList.remove('selected');});

	switch (state) {
	case 'play':
		this.$stateButton.children[1].classList.add('selected');
		break;
	case 'pause':
		this.$stateButton.children[0].classList.add('selected');
		break;
	case 'ended':
		this.$stateButton.children[2].classList.add('selected');
		break;
	default:
		Logger.warn('unknown player state ', state);
		break;
	}
};

PlayerView.prototype.setVolumeDisplay = function(volume, muted) {
	this.$volumeButton.querySelectorAll('i').forEach(function(elm) {elm.classList.remove('selected');});

	if (muted){
		this.$volumeButton.children[3].classList.add('selected');
	} else if (volume >= 50){
		this.$volumeButton.children[0].classList.add('selected');
	} else if (volume > 0){
		this.$volumeButton.children[1].classList.add('selected');
	} else {
		this.$volumeButton.children[2].classList.add('selected');
	}
	if (muted) {
		volume = 0;
	}
	this.$volumeRange.value = volume;
};

PlayerView.prototype.toggleFullScreen = function(){
	if (!this.fullscreen){
		Utils.launchIntoFullscreen(this.element);
		this.prepareControlsForFullScreen();
	} else {
		Utils.exitFullscreen();
		this.prepareControlsForInScreen();
	}
	this.fullscreen = Utils.isFullscreen();
	this.updateFullScreenState();
};

PlayerView.prototype.updateFullScreenState = function(){
	this.fullscreen = Utils.isFullscreen();

	this.$fullscreenButton.querySelectorAll('i').forEach(function(elm) {elm.classList.remove('selected');});

	if (this.fullscreen){
		this.$fullscreenButton.children[1].classList.add('selected');
	} else {
		this.$fullscreenButton.children[0].classList.add('selected');
	}
};

PlayerView.prototype.showSkipButton = function() {
	this.$skipButton.classList.remove('hidden');
};

PlayerView.prototype.hideSkipButton = function() {
	this.$skipButton.classList.add('hidden');
};

PlayerView.prototype.updateBufferProgression = function(loadFrac) {
	this.progressBar.setBufferRatio(loadFrac);
};

PlayerView.prototype.prepareControlsForFullScreen = function() {
	if (Utils.isMobileUser()) {
		return;
	}
	this.mouseMoveEventWatcherTimeoutId = null;
	var that = this;
	var element = this.element;
	this.element.addEventListener('mousemove', moveOnScreen);
	moveOnScreen(); // needed to be called to start the timers

	function moveOnScreen() {
		element.classList.remove('hidden_controls');
		clearTimeout(that.mouseMoveEventWatcherTimeoutId);
		that.mouseMoveEventWatcherTimeoutId = setTimeout(function() {
			element.classList.add('hidden_controls');
		}, 3000);
	}
};

PlayerView.prototype.prepareControlsForInScreen = function() {
	clearTimeout(this.mouseMoveEventWatcherTimeoutId);
	this.element.classList.remove('hidden_controls');
};



var ProgressBar = function(elmenetId) {
	this.progressBarElm = document.querySelector('#' + elmenetId);
	this.hit = this.progressBarElm.querySelector('.hit');
	this.circle = this.progressBarElm.querySelector('.progress_list .circle_container');
	this.progression = this.progressBarElm.querySelector('.progress_list .progression');
	this.visualisation = this.progressBarElm.querySelector('.progress_list .visualisation');
	this.loadedBar = this.progressBarElm.querySelector('.progress_list .loaded');
	this.tooltip = this.progressBarElm.querySelector('.progress_bar .progress_tooltip');


	this.totalDuration = 200; // in seconds

	var that = this;

	this.hit.addEventListener('click', onclick);

	this.hit.addEventListener('mousemove', onhover);
	this.hit.addEventListener('mouseleave', onleave);

	this.hit.addEventListener('touchstart', ontouchstart);
	this.hit.addEventListener('touchmove', ontouchmove);
	this.hit.addEventListener('touchend', ontouchend);

	EventEmitter(this);

	function ontouchstart(e) {
	  e.preventDefault();
	  e.stopPropagation();
	  var offsetX = e.changedTouches[0].pageX - getOffset(that.hit).left;
	  offsetX = Math.min(that.hit.clientWidth, Math.max(0, offsetX));
	  var ratio = offsetX / that.hit.clientWidth;

	  that.visualisation.style.transform = 'scaleX(' + ratio + ')';
	  that.tooltip.style.transform = 'translateX(-50%) translateX(' + offsetX + 'px)';
	  that.tooltip.innerHTML = Utils.stringifyTime(ratio * that.totalDuration);
	  that.tooltip.style.display = 'block';
	  that.tooltip.style.opacity = '1';
	  that.hit.style.height = '30px';
	  that.hit.style.bottom = '-9px';
	}

	function ontouchmove(e) {
	  e.preventDefault();
	  e.stopPropagation();
	  var hitOffset = getOffset(that.hit);
	  var offsetX = e.changedTouches[0].pageX - hitOffset.left;
	  var offsetY = e.changedTouches[0].pageY - hitOffset.top;
	  if (offsetY >= that.hit.clientHeight || offsetY < 0){
	    that.tooltip.style['background-color'] = '#F33';
	  } else {
	    that.tooltip.style['background-color'] = '';
	  }
	  offsetX = Math.min(that.hit.clientWidth, Math.max(0, offsetX));
	  var ratio = offsetX / that.hit.clientWidth;


	  that.visualisation.style.transform = 'scaleX(' + ratio + ')';
	  that.tooltip.style.transform = 'translateX(-50%) translateX(' + offsetX + 'px)';
	  that.tooltip.innerHTML = Utils.stringifyTime(ratio * that.totalDuration);
	  that.tooltip.style.display = 'block';
	  that.tooltip.style.opacity = '1';
	}

	function ontouchend(e) {
	  e.preventDefault();
	  e.stopPropagation();
	  that.tooltip.style.display = '';
	  that.tooltip.style.opacity = '';
	  that.visualisation.style.transform = 'scaleX(0)';
	  that.tooltip.style['background-color'] = '';
	  that.hit.style.height = '';
	  that.hit.style.bottom = '';
	  var hitOffset = getOffset(that.hit);
	  var offsetX = e.changedTouches[0].pageX - hitOffset.left;
	  var offsetY = e.changedTouches[0].pageY - hitOffset.top;
	  if (offsetX >= that.hit.clientWidth || offsetX < 0 || offsetY >= that.hit.clientHeight || offsetY < 0){
	    return;
	  }
	  var ratio = (offsetX) / that.hit.clientWidth;
	  that.emit('update', ratio * that.totalDuration);
	}


	function onclick(e) {
	  var ratio = (e.offsetX + 1) / e.target.offsetWidth;

	  that.emit('update', ratio * that.totalDuration);
	}

	function onhover(e) {
	  var ratio = (e.offsetX + 1) / e.target.offsetWidth;
	  that.visualisation.style.transform = 'scaleX(' + ratio + ')';
	  that.tooltip.style.transform = 'translateX(-50%) translateX(' + e.offsetX + 'px)';
	  that.tooltip.innerHTML = Utils.stringifyTime(ratio * that.totalDuration);
	}

	function onleave(_event) {
	  that.visualisation.style.transform = 'scaleX(0)';
	}


	// utils functions
	function getOffset(obj) {
	  var offsetLeft = 0;
	  var offsetTop = 0;
	  do {
	    if (!isNaN(obj.offsetLeft)) {
	      offsetLeft += obj.offsetLeft;
	    }
	    if (!isNaN(obj.offsetTop)) {
	      offsetTop += obj.offsetTop;
	    }
	  } while ((obj = obj.offsetParent));
	  return {left: offsetLeft, top: offsetTop};
	}
};

ProgressBar.prototype.setDuration = function(duration) {
	this.totalDuration = duration;
};

ProgressBar.prototype.setCurrentTime = function(time) {
	this.setCurrentRatio(time / this.totalDuration);
};
ProgressBar.prototype.setCurrentRatio = function(ratio) {
	this.circle.style.transform = 'translateX(' + ratio * this.hit.offsetWidth + 'px)';
	this.progression.style.transform = 'scaleX(' + ratio + ')';
};

ProgressBar.prototype.setBufferRatio = function(ratio) {
	this.loadedBar.style.transform = 'scaleX(' + ratio + ')';
};

var Player = function Player(app) {
	this.app = app;

	this.currentVideo = null;
	this.state = 'pause';
	this.startDate = new Date();
	this.pauseTime = 0;

	this.playerVolume = 50;
	this.muted = false;

	this.saveVolumeTimeout = null;

	YTPlayer.createPlayer();
	YTPlayer.setVolume(this.playerVolume);
	YTPlayer.unMute();
	YTPlayer.pause(this.time);
	this.bindTimeUpdate();

	YTPlayer.on('videoEnded', function() {
		this.state = 'ended';
		this.time = 0;
		this.view.setStateDisplay(this.state);
	}.bind(this));

	YTPlayer.on('bufferUpdate', function(loadFrac) {
		this.view.updateBufferProgression(loadFrac);
	}.bind(this));

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new PlayerView(this);
		var v = localStorage.getItem('volume');
		v = parseInt(v, 10);
		if (v) {
			this.setVolume(v);
		}
	}.bind(this));

	app.on('playlistEnabled', function() {
		this.view.showSkipButton();
	}.bind(this));
	app.on('playlistDisabled', function() {
		this.view.hideSkipButton();
	}.bind(this));

	app.on('keydown_up', function(event) {
		if (this.view.isVisible() && event.isInElement(this.view.element)) {
			event.preventDefault();
			event.stopPropagation();
			this.setVolume(Math.min(this.playerVolume + 5, 100));
		}
	}.bind(this));

	app.on('keydown_down', function(event) {
		if (this.view.isVisible() && event.isInElement(this.view.element)) {
			event.preventDefault();
			event.stopPropagation();
			this.setVolume(Math.max(this.playerVolume - 5, 0));
		}
	}.bind(this));

	app.on('keydown_space', function() {
		var lastTime = new Date().getTime();

		return function(event) {
			var now = new Date().getTime();
			if (now - lastTime < 100) return;
			lastTime = now;

			if (this.view.isVisible() && event.isInElement(this.view.element)) {
				event.preventDefault();
				event.stopPropagation();
				this.askChangeState();
			}
		}.bind(this);
	}.bind(this)());
};

Player.moduleName = Player.prototype.moduleName = 'player';

TiPlayerApp.modules.push(Player);

Player.prototype.listenSocket = function(socket) {
	var that = this;
	socket.on('player/update', function(data) {
		Logger.debug('player update data recieved', data);

		that.dataUpdate(data);
	});
};

// Socket commands
Player.prototype.play = function(time) {
	Logger.debug('play time %s', time);
	this.app.socket.emit('player/play', time);
};
Player.prototype.pause = function(time) {
	Logger.debug('pause time %s', time);
	this.app.socket.emit('player/pause', time);
};
Player.prototype.setVideo = function(videoID) {
	this.app.socket.emit('player/setVideo', {id: videoID});
};
Player.prototype.getData = function() {
	this.app.socket.emit('player/getData');
};

Player.prototype.dataUpdate = function(data) {
	this.currentVideo = data.video;
	// this.time = data.time;
	this.state = data.state;
	this.startDate = new Date(data.startDate);
	this.pauseTime = data.pauseTime;

	var currentTime = this.getCurrentTime();

	this.view.setVideoTitle(this.currentVideo.title, this.currentVideo.channelTitle);
	this.view.setStateDisplay(this.state);
	this.view.updateProgression(currentTime, this.currentVideo.duration);

	YTPlayer.setVideo(this.currentVideo.videoID);
	if (this.state == 'play') {
		YTPlayer.play(this.startDate);
	} else {
		YTPlayer.pause(this.pauseTime);
	}
};

Player.prototype.getCurrentTime = function() {
	if (this.state == 'play') {
		var time = (new Date()).getTime() / 1000 - this.startDate.getTime() / 1000 + TiPlayerApp.serverTimeOffset / 1000;
		if (this.currentVideo) {
			return time <= this.currentVideo.duration ? time : this.currentVideo.duration;
		} else {
			return time;
		}
	} else {
		return this.pauseTime;
	}
};

Player.prototype.changeProgress = function(time) {
	Logger.debug('change progress time %s', time);
	if (this.state == 'play' || this.state == 'ended') {
		this.play(time);
	} else {
		this.pause(time);
	}
};

Player.prototype.askChangeState = function() {
	var time = this.getCurrentTime();
	if (this.state == 'play') {
		this.pause(time);
	} else if (this.state == 'pause') {
		this.play(time);
	} else if (this.state == 'ended') {
		this.play(0);
	}
};

Player.prototype.switchMute = function() {
	if (this.muted) {
		this.muted = false;
		YTPlayer.unMute();
	} else {
		this.muted = true;
		YTPlayer.mute();
	}

	this.view.setVolumeDisplay(this.playerVolume, this.muted);
};

Player.prototype.setVolume = function(volume) {
	this.playerVolume = volume;

	YTPlayer.setVolume(volume);

	if (this.muted) {
		this.muted = false;
		YTPlayer.unMute();
	}

	this.view.setVolumeDisplay(this.playerVolume, this.muted);

	clearTimeout(this.saveVolumeTimeout);
	this.saveVolumeTimeout = setTimeout(function() {
		try {
			localStorage.setItem('volume', volume);
		} catch (e) {
			Logger.error('Unable to write in the localStorage');
			return;
		}
	}, 1000);
};


Player.prototype.bindTimeUpdate = function() {
	if (this._timeUpdateFunc) {
		this.unbindTimeUpdate();
	}
	this._timeUpdateFunc = function(time) {
		if (time === undefined) return;
		this.time = time;
		this.view.updateProgression(this.time, this.currentVideo.duration);
	}.bind(this);

	YTPlayer.on('timeUpdate', this._timeUpdateFunc);
};

Player.prototype.unbindTimeUpdate = function() {
	YTPlayer.removeListener('timeUpdate', this._timeUpdateFunc);
	delete this._timeUpdateFunc;
};

Player.prototype.skipVideo = function() {
	this.app.playlist.goToNextVideo();
};


Player.prototype.actionAddVideoToPlaylist = function() {
	this.app.playlist.addVideo(this.currentVideo.videoID);
};
Player.prototype.actionOpenYT = function() {
	var url = 'https://www.youtube.com/watch/' + this.currentVideo.videoID;
	var win = window.open(url, '_blank');
	win.focus();
};
Player.prototype.actionSearchRelated = function() {
	this.app.emit('searchRelatedVideos', this.currentVideo.videoID);
};

Player.prototype.toggleNativeControls = function() {
	var currentState = localStorage.getItem('debug-showNativeControls');
	try {
		if (currentState === 'true') {
			localStorage.setItem('debug-showNativeControls', false);
		} else {
			localStorage.setItem('debug-showNativeControls', true);
		}
		window.location.reload();
	} catch (e) {
		Logger.error('Unable to write in the localStorage');
		return;
	}
};

var PlaylistView = function PlaylistView(controller) {
	this.controller = controller;

	this.element = document.querySelector('#roomPlaylist');

	this.$checkActive = this.element.querySelector('#check_active');
	this.$modeButton = this.element.querySelector('#check_loop');
	this.$playlistList = this.element.querySelector('#playlist');
	this.$playlistClearButton = this.element.querySelector('#playlistClear');

	this.$playlistAvailableList = this.element.querySelector('#availablePlaylist');
	this.$playlistEnablerSection = this.element.querySelector('.playlist_selection_enabler');
	this.playlistAvailableItemTemplate = Handlebars.compile(this.element.querySelector('#available-playlist-template').innerHTML);

	this.$personalPlaylistMessage = this.element.querySelector('#personalPlaylistMessage');
	this.personalPlaylistMessageTemplate = Handlebars.compile(this.element.querySelector('#personal-playlist-message').innerHTML);
	this.element.querySelector('#personal-playlist-message').remove();

	this.videoTemplate = Handlebars.compile(this.element.querySelector('#playlist-video-template').innerHTML);

	this.element.querySelector('#playlist-video-template').remove();


	this.activeIndex = -1;
	this.indexActive = null;
	this.playlistId = null;
	this.canEdit = false;

	// TODO: fix this dependency to socket ... here it is badly injected
	this.socket = null;
	this.elementCreationFunction = createElement.bind(this);

	this.createPlaylistDownloader();

	this._bindEvent();

	function createElement(controller, data, index) {
		if (data === undefined || data === null) return null;


		var parent = document.createElement('div');
		parent.innerHTML = this.videoTemplate({
			videoID: data.videoID,
			title: data.title,
			channel:data.channelTitle,
			time: Utils.stringifyTime(data.duration),
			editMode: this.canEdit
		});
		var elm = parent.firstChild;

		elm.querySelector('button.play_action').addEventListener('click', function(e) {
			e.stopPropagation();
			this.controller.goToVideo(index);
		}.bind(this));

		elm.querySelector('button.youtube').addEventListener('click', function(e) {
			e.stopPropagation();
			var url = 'https://www.youtube.com/watch/' + data.videoID;
			var win = window.open(url, '_blank');
			win.focus();
		}.bind(this));

		if (this.canEdit) {
			elm.querySelector('button.move_up').addEventListener('click', function(e) {
				e.stopPropagation();
				controller.move(index, index - 1);
			}.bind(this));

			elm.querySelector('button.delete').addEventListener('click', function(e) {
				e.stopPropagation();
				controller.remove(index);
			}.bind(this));
		} else {
			elm.querySelector('button.move_up').disabled = true;
			elm.querySelector('button.delete').disabled = true;
		}

		if (this.activeIndex == index) {
			elm.classList.add('selected');
		}

		// Make title scroll if it overflow
		ScrollingTextLib.add(elm.querySelector('.description .title span'));

		return elm;
	}
};

PlaylistView.prototype = new View();


PlaylistView.prototype._bindEvent = function() {
	this.$checkActive.addEventListener('click', (function(){
		this.controller.askChangeActive();
	}).bind(this));
	this.$modeButton.addEventListener('click', (function(){
		this.controller.askChangeMode();
	}).bind(this));

	this.$playlistClearButton.addEventListener('click', function(e) {
		e.stopPropagation();
		if (confirm('Cette action va supprimer definitivement la playlist, confirmer ?')) {
			this.controller.askClear();
		}
	}.bind(this));

	var lastCall = new Date(0);

	var updateAvailablePlaylists = function(_event) {
		if (new Date().getTime() - lastCall.getTime() < 1000 ) return;
		lastCall = new Date();
		this.controller.getAvailablePlaylist();
	}.bind(this);

	this.$playlistEnablerSection.addEventListener('mouseenter', updateAvailablePlaylists);
	this.$playlistEnablerSection.addEventListener('touchenter', updateAvailablePlaylists);
	this.$playlistEnablerSection.addEventListener('focusin', updateAvailablePlaylists);
};

PlaylistView.prototype.setSocket = function(socket) {
	this.socket = socket;
	this.createPlaylistDownloader();
};

PlaylistView.prototype.setCanEdit = function(canEdit) {
	this.canEdit = canEdit;
};

PlaylistView.prototype.createPlaylistDownloader = function() {
	if (this.playlistDownloader) {
		this.virtualScrollController.destroy();
		this.playlistDownloader.delete();
		this.playlistDownloader = null;
	}
	if (this.playlistId) {
		this.playlistDownloader = new playlistDownloader(this.socket, this.playlistId, this.elementCreationFunction.bind(this), true);

		this.virtualScrollController = VirtualScroll.create(
			this.$playlistList,
			[],
			this.playlistDownloader.createElementWithMiddleware,
			45,
			10
		);

		this.playlistDownloader.on('dataUpdate', function(data) {
			this.virtualScrollController.onDataUpdate(data);
		}.bind(this));
		this.playlistDownloader.on('infoUpdate', function(playlistInfo) {
			this.updatePersonalPlaylistMessage(playlistInfo);
		}.bind(this));
	}
};


PlaylistView.prototype.setMode = function(mode) {
	this.$modeButton.querySelectorAll('i').forEach(function(elm) { elm.classList.remove('selected'); });

	switch (mode){
	case 0:
		this.$modeButton.children[0].classList.add('selected');
		break;
	case 1:
		this.$modeButton.children[1].classList.add('selected');
		break;
	case 2:
		this.$modeButton.children[2].classList.add('selected');
		break;
	}
};

PlaylistView.prototype.setActive = function(active) {
	this.$checkActive.querySelectorAll('i').forEach(function(elm) { elm.classList.remove('selected'); });

	if (active) {
		this.$checkActive.children[0].classList.add('selected');
	} else {
		this.$checkActive.children[1].classList.add('selected');
	}
};

PlaylistView.prototype.setSelected = function(index) {
	if (this.activeIndex == index) return;
	this.activeIndex = index;
	if (!this.playlistDownloader) return;
	this.virtualScrollController.scrollToPos(index);
	this.virtualScrollController.onDataUpdate();
};

PlaylistView.prototype.updatePlaylistId = function(playlistId) {
	if (this.playlistId != playlistId) {
		this.playlistId = playlistId;
		if (this.socket) this.createPlaylistDownloader();
	}
};

PlaylistView.prototype.setPlaylistAvailable = function(playlistInfos) {
	this.$playlistAvailableList.innerHTML = '';
	for (var i = 0 ; i < playlistInfos.length ; i++) {
		var playlistInfo = playlistInfos[i];
		var html = this.playlistAvailableItemTemplate(playlistInfo);
		this.$playlistAvailableList.insertAdjacentHTML('beforeend', html);
		this.$playlistAvailableList.lastChild.addEventListener('click', function(playlistId) {
			this.controller.setCurrentPlaylist(playlistId);
		}.bind(this, playlistInfo.playlistId));
	}

};

PlaylistView.prototype.updatePersonalPlaylistMessage = function(playlistInfo) {
	var html = '';
	if (playlistInfo.roomPlaylist) {
		this.$personalPlaylistMessage.classList.add('hidden');
	} else {
		this.$personalPlaylistMessage.classList.remove('hidden');
		var visibleName = playlistInfo.creator.visibleName;
		html = this.personalPlaylistMessageTemplate({
			visibleName: visibleName,
			title: playlistInfo.title
		});
	}
	this.$personalPlaylistMessage.innerHTML = html;
};

var Playlist = function Playlist(app) {
	this.app = app;

	this.mode = 0;
	this.active = false;

	this.videoPositions = {};
	this.lastVideoIndex = 0;

	this.playlistId = null;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
		this.view.setSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new PlaylistView(this);
	}.bind(this));
};

Playlist.moduleName = Playlist.prototype.moduleName = 'playlist';

TiPlayerApp.modules.push(Playlist);

Playlist.prototype.listenSocket = function(socket) {
	var that = this;
	socket.on('room/playlist/update', function(data) {
		// data: {active: boolean, mode: integer, indexActuel; onteger, playlistId: string}
		that.dataUpdate(data);
	});
	socket.on('playlist/playlistAvailable', function(playlistInfos) {
		that.view.setPlaylistAvailable(playlistInfos);
	});
};

Playlist.prototype.addVideo = function(videoID) {
	this.app.socket.emit('playlist/addVideo', {playlistId: this.getRoomPlaylist(), videoID: videoID, origin: 'youtube'});
};
Playlist.prototype.removeVideo = function(index) {
	this.app.socket.emit('playlist/removeVideo', {playlistId: this.getRoomPlaylist(), position: index});
};
Playlist.prototype.moveVideo = function(from, to) {
	this.app.socket.emit('playlist/moveVideo', {playlistId: this.getRoomPlaylist(), indexFrom: from, indexTo: to});
};
Playlist.prototype.addPlaylist = function(YTPlaylistId) {
	this.app.socket.emit('playlist/addPlaylist', {playlistId: this.getRoomPlaylist(), YTPlaylistId: YTPlaylistId, origin: 'youtube'});
};
Playlist.prototype.clear = function() {
	this.app.socket.emit('playlist/clear', {playlistId: this.getRoomPlaylist()});
};

// Playlist state
Playlist.prototype.enable = function() {
	this.app.socket.emit('roomPlaylist/enable');
};
Playlist.prototype.disable = function() {
	this.app.socket.emit('roomPlaylist/disable');
};
Playlist.prototype.setMode = function(mode) {
	this.app.socket.emit('roomPlaylist/setMode', {mode: mode});
};
Playlist.prototype.goTo = function(index) {
	this.app.socket.emit('roomPlaylist/goTo', {position: index});
};

Playlist.prototype.goToNextVideo = function(_index) {
	this.app.socket.emit('roomPlaylist/goToNextVideo');
};
Playlist.prototype.goToPrevVideo = function(_index) {
	this.app.socket.emit('roomPlaylist/goToPrevVideo');
};
Playlist.prototype.getAvailablePlaylist = function() {
	this.app.socket.emit('playlist/getAvailable');
};
Playlist.prototype.setCurrentPlaylist = function(playlistId) {
	this.app.socket.emit('roomPlaylist/setCurrentPlaylist', {playlistId: playlistId});
};
Playlist.prototype.getRoomPlaylist = function() {
	return 'room-' + this.app.room.id;
};

Playlist.prototype.dataUpdate = function(data) {
	Logger.debug('playlist data update', data);
	if (data.active !== undefined) {
		this.setActive(data.active);
	}
	if (data.indexActual !== undefined) {
		this.applySelectedVideo(data.indexActual);
	}
	if (data.mode !== undefined) {
		this.mode = data.mode;
		this.view.setMode(data.mode);
	}
	if (data.playlistId !== undefined)  {
		this.playlistId = data.playlistId;
		this.view.updatePlaylistId(data.playlistId);
	}
	if (data.creator) {
		if (data.creator.id == this.app.account.userData.id) {
			this.view.setCanEdit(true);
		} else {
			this.view.setCanEdit(false);
		}
	} else {
		this.view.setCanEdit(true);
	}
};

Playlist.prototype.applySelectedVideo = function(index) {
	if (index == -1) {
		this.view.setSelected(null);
	} else {
		this.view.setSelected(index);
	}
};

Playlist.prototype.askChangeMode = function() {
	var newMode = (this.mode + 1) % 3;
	this.setMode(newMode);
};

Playlist.prototype.askClear = function() {
	this.clear();
};

Playlist.prototype.askChangeActive = function() {
	this.active = !this.active;
	if (this.active) {
		this.enable();
	} else {
		this.disable();
	}
};

Playlist.prototype.setActive = function(state) {
	if (state) {
		this.active = true;
		this.app.emit('playlistEnabled');
		this.view.setActive(true);
	} else {
		this.active = false;
		this.app.emit('playlistDisabled');
		this.view.setActive(false);
	}
};

Playlist.prototype.goToVideo = function(index) {
	this.goTo(index);
};

Playlist.prototype.moveUpVideo = function(index) {
	this.moveVideo(index - 1, index);
};

Playlist.prototype.deleteVideo = function(index) {
	this.removeVideo(index);
};

var SearchView = function SearchView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#searchSection');

	this.$inputSearch = this.element.querySelector('#search_input_text');
	this.$buttonSearch = this.element.querySelector('#search_button');
	this.$resultList = this.element.querySelector('#result_list');
	this.$loadingDiv = this.element.querySelector('#loading_search');

	this.$playlistAddStatus = this.element.querySelector('#add_playlist_waiter');

	this.resultVideoTemplate = Handlebars.compile(this.element.querySelector('#search-result-template').innerHTML);

	this._bindEvents();
};

SearchView.prototype = new View();

SearchView.prototype._bindEvents = function() {
	var that = this;
	this.$buttonSearch.addEventListener('click', function(){
		that.controller.search();
	});
	this.$inputSearch.addEventListener('keypress', function(e){
		var message = e.target.value.trim();
		if (e.keyCode == 13 && message !== ''){
			that.controller.search();
		}
	});
	this.$resultList.addEventListener('scroll', function(_event){
		that.checkBottomPosition();
	});
};

SearchView.prototype.bindResultsEvents = function(elm) {
	var that = this;
	elm.querySelector('.result_add_button').addEventListener('click', function(e) {
		e.stopPropagation();
		if (this.dataset.type == 'video') {
			that.controller.addVideoToPlaylist(this.dataset.id);
		}
		else if (this.dataset.type == 'playlist') {
			that.controller.addPlaylistToPlaylist(this.dataset.id);
		}
	}.bind(elm));
	elm.addEventListener('click', function(e) {
		e.stopPropagation();
		if (this.dataset.type == 'video') {
			that.controller.videoSelected(this.dataset.id);
		} else if (this.dataset.type == 'playlist') {
			that.controller.addPlaylistToPlaylist(this.dataset.id);
		}
	});
};

SearchView.prototype.addResults = function(results) {
	var html, elm;
	for (var i = 0 ; i < results.length ; i++) {
		var data = results[i];
		if (data.type == 'video'){
			html = this.resultVideoTemplate({
				title: data.title,
				thumbnailsrc: data.thumbnailsrc,
				channel: data.channel,
				time: Utils.stringifyTime(data.time),
				viewCount: Utils.stringifycountView(data.viewCount),
				likeRatio: data.likeRatio * 100,
				dislikeRatio: 100 * (1 - Math.abs(data.likeRatio))
			});
		} else {
			html = this.resultVideoTemplate(data);
		}
		this.$loadingDiv.insertAdjacentHTML('beforebegin', html);

		elm = this.$loadingDiv.previousElementSibling;

		elm.dataset.id = data.id;
		elm.dataset.type = data.type;

		this.bindResultsEvents(elm);

		// Make title scroll if it overflow
		ScrollingTextLib.add(elm.querySelector('.description .title span'));
	}
	this.$resultList.scrollIntoView();
};

SearchView.prototype.clearResults = function() {
	var results = this.element.querySelectorAll('.result');
	if (!results) {
		return;
	}
	for (var i = 0 ; i < results.length ; i++) {
		results[i].remove();
	}
};

SearchView.prototype.showLoading = function() {
	this.$loadingDiv.classList.remove('hidden');
};

SearchView.prototype.hideLoading = function() {
	this.$loadingDiv.classList.add('hidden');
};


SearchView.prototype.getSearchQuery = function() {
	return this.$inputSearch.value;
};

SearchView.prototype.checkBottomPosition = function() {
	var elm = this.$resultList;
	if (elm.scrollTop >= elm.scrollHeight - elm.clientHeight){
		this.controller.loadMore();
	}
};

SearchView.prototype.showPlaylistAddStatus = function() {
	this.$playlistAddStatus.classList.remove('hidden');
};
SearchView.prototype.hidePlaylistAddStatus = function() {
	this.$playlistAddStatus.classList.add('hidden');
};

var Search = function Search(app) {
	this.app = app;

	this.lastSearchType = null;

	this.relatedvideoID = null;
	this.token = null;

	app.on('initialized', function() {
		this.view = new SearchView(this);
	}.bind(this));

	// this.app.emit('searchRelatedVideos', this.currentVideo.id)
	app.on('searchRelatedVideos', function(videoID) {
		this.relatedvideoID = videoID;
		this.view.clearResults();
		this._searchRelated();
	}.bind(this));
};

Search.moduleName = Search.prototype.moduleName = 'search';

TiPlayerApp.modules.push(Search);

Search.prototype.addVideoToPlaylist = function(id) {
	this.app.playlistAdder.addVideoToTiPlaylist(id);
};

Search.prototype.addPlaylistToPlaylist = function(YTPlaylistId) {
	this.app.playlistAdder.getPlaylistChoice(function(playlistId) {
		this.app.socket.emitWithResponse('playlist/addPlaylist', {playlistId: playlistId, YTPlaylistId: YTPlaylistId, origin: 'youtube'}, function(err, _response) {
			if (err) {
				alert(err || 'Vous n\'etes pas autorisé à réaliser cette action');
				this.view.hidePlaylistAddStatus();
				return;
			}
			this.view.hidePlaylistAddStatus();
		}.bind(this));
	}.bind(this));
};

Search.prototype.videoSelected = function(id) {
	// TODO: use internal events
	this.app.socket.emit('player/setVideo', {videoID: id, origin: 'youtube'});
};


Search.prototype.search = function() {
	if (!YTData.APILoaded) {
		var that = this;
		setTimeout(function(){
			that.search();
		}, 500);
		return;
	}
	this.view.clearResults();
	this._search();
};

Search.prototype._search = function(token){
	if (this.loading){ return; }
	this.lastSearchType = 'video';
	if (!token) {
		this.maxResult = false;
	}
	if (this.maxResult) return 'no more video';
	// recuperation de la recherche a effectuer
	var q = this.view.getSearchQuery();
	// creation de la requete
	var request = gapi.client.youtube.search.list({
		q: q,
		maxresult: 15,
		// type: 'video',
		pageToken: token || '',
		fields: 'items/id,nextPageToken,prevPageToken',
		part: 'snippet'
	});

	// execution de la requete
	this._executeRequest(request);

};



Search.prototype._searchRelated = function(token){
	if (this.loading) { return; }
	this.lastSearchType = 'relatedVideo';
	if (!token) {
		this.maxResult = false;
	}
	if (this.maxResult) return 'no more video';
	var request = gapi.client.youtube.search.list({
		relatedToVideoId: this.relatedvideoID,
		maxresult: 15,
		type: 'video',
		pageToken: token || '',
		fields: 'items/id,nextPageToken,prevPageToken',
		part: 'snippet'
	});
	// execution de la requete
	this._executeRequest(request);
};

Search.prototype._executeRequest = function(request) {
	var that = this;
	this.loading = true;

	this.view.showLoading();

	that.lastRequest = request;
	request.execute(function(response) {
		if (response && response.result) {
			that.nextToken = response.result.nextPageToken;
		} else {
			that.nextToken = undefined;
		}
		if (that.nextToken === undefined){
			that.maxResult = true;
		}
		// recuperation des ids des video
		var videoIDs = [];
		var playlistIds = [];
		for (var i = 0 ; i < response.result.items.length ; i++){
			if (response.result.items[i].id.kind == 'youtube#video'){
				videoIDs.push(response.result.items[i].id.videoId);
			}
			if (response.result.items[i].id.kind == 'youtube#playlist'){
				playlistIds.push(response.result.items[i].id.playlistId);
			}
		}
		var videoDone = false;
		var playlistDone = false;

		function onDone() {
			if (!videoDone || !playlistDone) { // if playlist or video not already done
				return;
			}
			that.loading = false;

			that.view.hideLoading();
			// verification du nombre d'elements
			that.view.checkBottomPosition();
		}
		// recuperation les informations detaillees des videos
		YTData.getVideoData(videoIDs, function(err, videoData) {
			if (err) {
				Logger.error(err, videoIDs);
				videoData = [];
			} else {
				var results = [];
				for (var i = 0; i < videoData.length; i++) {
					var v = videoData[i];
					results.push({type:'video', id: v.id, title: v.title, thumbnailsrc: v.thumbnail, channel: v.channelTitle, time: v.duration, viewCount: v.viewCount, likeRatio: v.likeRatio});
				}
				that.view.addResults(results);
			}
			videoDone = true;
			onDone();
		});
		YTData.getPlaylistDescription(playlistIds, function(err, playlistData) {
			if (err) {
				Logger.error(err);
				playlistData = [];
			} else {
				var results = [];
				for (var i = 0; i < playlistData.length; i++) {
					var p = playlistData[i];
					results.push({type:'playlist', id: p.id, title: p.title, channel: p.channel, thumbnailsrc: p.thumbnail, videoCount: p.videoCount});
				}
				that.view.addResults(results);
			}
			playlistDone = true;
			onDone();
		});
	});
};

Search.prototype.loadMore = function() {
	if (this.loading){
		return;
	}
	if (this.nextToken !== undefined && !this.maxResult){
		if (this.lastSearchType == 'video') {
			this._search(this.nextToken);
		} else if (this.lastSearchType == 'relatedVideo') {
			this._searchRelated(this.nextToken);
		}
	}
};

var RoomSelectorView = function RoomSelectorView(controller) {
	this.controller = controller;
	this.element = document.querySelector('#roomSelectorSection');
	this.roomList = this.element.querySelector('#roomList');
	this.$closeView = this.element.querySelector('.close');

	this.$createRoomName = this.element.querySelector('#roomCreationName');
	this.$createRoomButton = this.element.querySelector('#roomConfirmName');
	this.$createRoomError = this.element.querySelector('#roomErrorName');

	this.$roomButton = document.querySelector('#roomsButton');

	this.roomDivTemplate = Handlebars.compile(document.querySelector('#room-div-template').innerHTML);

	this.roomElements = {};

	this._bindEvents();
	viewManager.registerView('roomSelector', this, '/rooms');
};

RoomSelectorView.prototype = new View();

RoomSelectorView.prototype._bindEvents = function() {
	var that = this;

	this.$closeView.addEventListener('click', function() {
		that.controller.closeClicked();
	});

	this.$roomButton.addEventListener('click', function() {
		that.controller.displayGrid();
	});

	this.$createRoomButton.addEventListener('click', function() {
		that.controller.createRoomButton();
	});
};

RoomSelectorView.prototype.updateRoomList = function(roomList) {
	var keys = {};
	var key;
	for (key in this.roomElements) {
		keys[key] = false;
	}
	for (var i = 0 ; i < roomList.length ; i++) {
		var data = roomList[i];
		var elm = this.roomElements[data.id];
		keys[data.id] = true;
		if (elm) {
			this.updateRoomDescription(elm, data);
		} else {
			this.addRoom(data);
		}
	}
	// remove old rooms
	for (key in this.roomElements) {
		if (!keys[key]) {
			this.roomElements[key].remove();
			delete this.roomElements[key];
		}
	}
};

RoomSelectorView.prototype.addRoom = function(data) {
	var html = this.roomDivTemplate(data);
	this.roomList.insertAdjacentHTML('beforeend', html);

	this.roomElements[data.id] = this.roomList.querySelector('.room_div[data-room-id="' + data.id + '"]');

	this.roomElements[data.id].addEventListener('click', function() {
		this.controller.joinById(data.id);
	}.bind(this));

};

RoomSelectorView.prototype.updateRoomDescription = function(element, data) {
	// data = {id, actualVideoTitle, connectedUsers, creator: {id}, name, thumbnailUrl}
	element.querySelector('.room_name').innerHTML = data.name;
	element.querySelector('.room_user_online').innerHTML = data.connectedUsers;
	element.querySelector('.actual_title').innerHTML = data.actualVideoTitle;
	element.querySelector('.thumbnail_video').src = data.thumbnailUrl;
};

RoomSelectorView.prototype.blockMode = function() {
	this.$closeView.hidden = true;
};

RoomSelectorView.prototype.hoverMode = function() {
	this.$closeView.hidden = false;
};

RoomSelectorView.prototype.errorMessage = function(message) {
	if (message) {
		this.$createRoomError.classList.remove('hidden');
	} else {
		this.$createRoomError.classList.add('hidden');
	}
	this.$createRoomError.innerHTML = message;

	setTimeout(function() {
		this.errorMessage();
	}.bind(this), 4000);
};

var RoomSelector = function RoomSelector(app) {
	this.app = app;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('accountReady', function() {
		this.getRooms();
	}.bind(this));

	app.on('initialized', function() {
		this.view = new RoomSelectorView(this);
	}.bind(this));


	app.on('roomJoined', function() {
		viewManager.hideView('roomSelector');
		this.stopUpdater();
	}.bind(this));


	app.on('openRoomSelector', function() {
		this.displayGridMain();
	}.bind(this));

	app.on('openRoom', function(roomName) {
		this.joinByName(roomName);
	}.bind(this));
};

RoomSelector.moduleName = RoomSelector.prototype.moduleName = 'roomSelector';

TiPlayerApp.modules.push(RoomSelector);

RoomSelector.prototype.listenSocket = function(socket) {
	socket.on('rooms/data', function(data) {
		this.view.updateRoomList(data);
	}.bind(this));
};

RoomSelector.prototype.getRooms = function() {
	this.app.socket.emit('rooms/getAll');
};

RoomSelector.prototype.joinByName = function(roomName) {
	this.app.socket.emitWithResponse('room/join', {name: roomName}, function(err, _response) {
		if (err !== null) {
			alert(err);
			this.app.emit('openRoomSelector');
		}
	}.bind(this));
};

RoomSelector.prototype.joinById = function(roomID) {
	this.app.socket.emit('room/join', {id: roomID});
};

RoomSelector.prototype.leave = function(roomName) {
	this.app.socket.emit('room/leave', {name: roomName});
};

RoomSelector.prototype.createRoomButton = function() {
	viewManager.displayView('roomCreation');
};

RoomSelector.prototype.closeClicked = function() {
	viewManager.hideView('roomSelector');
	this.stopUpdater();
};

RoomSelector.prototype.displayGridMain = function() {
	this.view.blockMode();
	viewManager.displayView('roomSelector');
	this.startUpdater();
	this.app.emit('roomSelector/open');
};

RoomSelector.prototype.displayGrid = function() {
	this.view.hoverMode();
	viewManager.displayView('roomSelector');
	this.startUpdater();
	this.app.emit('roomSelector/open');
};

RoomSelector.prototype.startUpdater = function() {
	this.app.socket.emit('rooms/startListenChanges');
};

RoomSelector.prototype.stopUpdater = function() {
	this.app.socket.emit('rooms/stopListenChanges');
};

var UserlistView = function UserlistView(controller) {
	this.controller = controller;
	this.userElements = {};
	this.connectedUsers = 0;

	this.$userCountButton = document.querySelector('#onlineUsers');
	this.$userCounter = this.$userCountButton.querySelector('.userCounter');
	this.$userlist = document.querySelector('#onlineList');

	this.userUserListTemplate = Handlebars.compile(document.querySelector('#userlist-user-template').innerHTML);

	this._bindEvents();
};

UserlistView.prototype = new View();

UserlistView.prototype._bindEvents = function() {
	var that = this;
	this.$userCountButton.addEventListener('click', function() {
		that.$userlist.classList.toggle('show');
	});
};

UserlistView.prototype.addUser = function(data) {
	if (this.userElements[data.id]) {
		this.removeUser(data.id);
	}
	var html = this.userUserListTemplate({username: data.username, visibleName: data.visibleName, avatar_src: data.profilePicture});
	this.$userlist.insertAdjacentHTML('beforeend', html);

	this.userElements[data.id] = this.$userlist.lastChild;
	this.connectedUsers++;
	this.updateUserCountView();
};

UserlistView.prototype.removeUser = function(userId) {
	if (this.userElements[userId]) {
		this.userElements[userId].remove();
		delete this.userElements[userId];
		this.connectedUsers--;
		this.updateUserCountView();
	}
};

UserlistView.prototype.updateUserInfo = function(userInfo) {
	var elm = this.userElements[userInfo.id];
	if (elm !== undefined) {
		elm.querySelector('.avatar_img').src = userInfo.profilePicture;
		elm.querySelector('.username').title = userInfo.username;
		elm.querySelector('.username').innerHTML = userInfo.visibleName;
	}
};

UserlistView.prototype.setUserList = function(users) {
	this.clearUserList();

	for (var i = 0 ; i < users.length ; i++) {
		this.addUser(users[i]);
	}
};

UserlistView.prototype.clearUserList = function() {
	for (var userId in this.userElements) {
		this.userElements[userId].remove();
	}
	this.userElements = {};
	this.connectedUsers = 0;
	this.updateUserCountView();
};

UserlistView.prototype.updateUserCountView = function() {
	this.$userCounter.innerHTML = this.connectedUsers;
};

var Userlist = function Userlist(app) {
	this.app = app;

	app.on('startConnection', function() {
		this.listenSocket(app.socket);
	}.bind(this));

	app.on('initialized', function() {
		this.view = new UserlistView();
	}.bind(this));
};

Userlist.moduleName = Userlist.prototype.moduleName = 'userlist';

TiPlayerApp.modules.push(Userlist);

Userlist.prototype.listenSocket = function(socket) {
	socket.on('userJoin', function(userInfo) {
		this.view.addUser(userInfo);

	}.bind(this));

	socket.on('userLeave', function(userInfo) {
		this.view.removeUser(userInfo.id);
	}.bind(this));

	socket.on('userData', function(userData) {
		this.view.updateUserInfo(userData);
	}.bind(this));
};


Userlist.prototype.setData = function(users) {
	this.view.setUserList(users);
};
